import { GET_WISH_LIST_SUCCESS } from '@store/actions/wishlist'

const init = {
  list: [],
}

export default function wishlistReducer(state = init, action) {
  switch (action.type) {
    case GET_WISH_LIST_SUCCESS:
      return { ...state, list: action.list }
    default:
      return state
  }
}
