import { setLang } from '@translation'

export const SET_LANGUAGE = 'SET_LANGUAGE'

export const setLanguage = (payload) => {
  return {
    type: SET_LANGUAGE,
    selectedLanguage: payload,
  }
}

export const onSetLanguage = (data) => {
  return async (dispatch) => {
    await setLang(data.key)
    if (typeof window !== 'undefined') {
      window.location.reload()
    }
    dispatch(setLanguage(data))
  }
}
