/* eslint-disable no-nested-ternary */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Translation from '@translation'
import { addToCart } from '@store/actions/cart'
import { addProductToWishList } from '@store/actions/wishlist'
import {
  getPrimaryImage,
  getPrice,
  calculateDiscount,
  processProductDetails,
  getSumForProduct,
} from 'helpers/helperFuncs'
import parse from 'html-react-parser'
import { toast } from 'react-toastify'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import QuantityInput from '@components/QuantityInput'

class ProductModal extends PureComponent {
  state = {
    packageQuantity: 1,
    metricUnitQuantity: '',
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  componentDidMount() {
    const { onClose } = this.props
    window.jQuery('#productDetailModal').modal({
      backdrop: true,
    })
    window.jQuery('#productDetailModal').on('hidden.bs.modal', () => {
      onClose()
    })
  }

  async addToCart(id) {
    const { selectedLanguage } = this.props
    const T = Translation(selectedLanguage)
    const productId = id
    const sessionId = localStorage.getItem('sessionId')

    const product = {
      units: this.state.packageQuantity,
      productId,
      sessionId,
    }

    const { cartProducts } = this.props

    const alreadyInCart = cartProducts.find((p) => p.id === productId && p.units === this.state.quantity)

    if (alreadyInCart) {
      toast.warning(T.product_in_cart)
    } else {
      await this.props.addToCart(product, this.props.selectedLanguage.key)
      toast.success(T.add_product)
      // close modal
      this.props.onClose()
    }
  }

  addToWishlist = (p) => {
    const { selectedLanguage } = this.props
    const T = Translation(selectedLanguage)

    this.props
      .doAddToWishlist(p)
      .then(() => {
        toast.success(T.added_to_wishlist)
      })
      .catch((error) => {
        toast.error(error.message)
      })
  }

  incrementOrDecrement = (type) => {
    const p = this.props.product
    const newQ = this.state.packageQuantity + (type === 'inc' ? 1 : -1)

    if (newQ > 0 && newQ <= p.inventory) {
      this.setState({
        packageQuantity: newQ,
        metricUnitQuantity: Number((newQ * p.amountInPackage).toFixed(2)),
      })
    } else if (type === 'inc') {
      const { selectedLanguage } = this.props
      const T = Translation(selectedLanguage)

      toast.error(T.maximum_pieces_exceeded)
    }
  }

  onChangeQuantity = (e) => {
    const p = this.props.product
    const { packageQuantity } = this.state
    const metricQuantity = this.state.metricUnitQuantity || p.amountInPackage

    if (e.target.name === 'packageQuantity') {
      this.setState({
        packageQuantity,
        metricUnitQuantity: Number((packageQuantity * p.amountInPackage).toFixed(2)),
      })
    } else if (e.target.name === 'metricUnitQuantity') {
      const units = Math.ceil(metricQuantity / p.amountInPackage)

      this.setState({
        packageQuantity: units,
        metricUnitQuantity: units * p.amountInPackage,
      })
    }
  }

  render() {
    const { product: p, selectedCurrency, selectedLanguage, vatType } = this.props
    const { wantMoreQuantity } = this.state
    const T = Translation(selectedLanguage)

    if (!p) {
      return null
    }
    const product = processProductDetails(p)
    const productAvailable = product.available

    return (
      <div
        className={`modal fade modal-wrapper ${!!product.id && 'show'}`}
        id="productDetailModal"
        role="dialog"
        aria-labelledby="product modal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body pt-15 pb-10 pt-sm-10 pb-sm-30 pb-xs-50">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => window.jQuery('#productDetailModal').modal('hide')}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div className="modal-inner-area row">
                <div className="col-lg-5 col-md-5 col-sm-5">
                  <div className="product-details-left">
                    <div className="product-details-images slider-navigation-1">
                      <div className="lg-image">
                        <LazyLoadImage src={getPrimaryImage(product.images)} alt="Single Product Image" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7">
                  <div className="product-details-view-content pt-40 pt-sm-25">
                    <div className="product-info">
                      <h2>
                        {product.brand && <span>{product.brand.name} | </span>}
                        <span>{product.name}</span>
                      </h2>
                      <div className="price-box pb-20">
                        <span className="new-price new-price-2">
                          {getPrice(product, selectedCurrency, false, vatType)}
                        </span>
                        {product.oldPriceWithVat && (
                          <span className="old-price">{getPrice(product, selectedCurrency, true, vatType)}</span>
                        )}
                      </div>
                      {product.oldPriceWithVat && (
                        <div className="sticker-2">
                          <span>{calculateDiscount(product)}</span>
                        </div>
                      )}
                      <div className="product-desc">{parse(product.description)}</div>
                      <div className="single-add-to-cart">
                        <div className="cart-quantity mt-0">
                          {productAvailable && typeof p.inventory !== 'undefined' && (
                            <>
                              <div className="quantity">
                                <label>Quantity</label>
                                <div className="d-flex align-items-center">
                                  <QuantityInput
                                    name="packageQuantity"
                                    value={this.state.packageQuantity}
                                    onClick={(e) => {
                                      e.target.select()
                                    }}
                                    onBlur={this.onChangeQuantity}
                                    onChange={(e) => {
                                      const val = e.target.value
                                      if (isNaN(val) && val !== '.') {
                                        return
                                      }
                                      this.setState({
                                        packageQuantity: Number(val) < 1 ? 1 : Number(val),
                                      })
                                    }}
                                    onIncrementOrDecrement={this.incrementOrDecrement}
                                  />
                                  <span className="package-span align-items-center">{T.pcs}</span>
                                  {p.productMetricUnit.name !== 'pcs' && (
                                    <>
                                      <span className="mx-1">=</span>
                                      <QuantityInput
                                        name="metricUnitQuantity"
                                        onClick={(e) => {
                                          e.target.select()
                                        }}
                                        style={{ width: '3rem' }}
                                        value={this.state.metricUnitQuantity || p.amountInPackage}
                                        onBlur={this.onChangeQuantity}
                                        onChange={(e) => {
                                          const val = e.target.value
                                          if (isNaN(val) && val !== '.') {
                                            return
                                          }
                                          this.setState({
                                            metricUnitQuantity: Number(val) < 1 ? 1 : Number(val),
                                          })
                                        }}
                                        onIncrementOrDecrement={this.incrementOrDecrement}
                                      />
                                      <span className="quantity-span">{p.productMetricUnit.name}</span>
                                      <span className="price-span d-flex align-items-center">
                                        <span className="mr-2">=</span>
                                        {getSumForProduct(p, selectedCurrency, this.state.packageQuantity, vatType)}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                              <br />
                              <br />
                              <br />
                            </>
                          )}
                          <button
                            type="button"
                            disabled={!productAvailable}
                            onClick={() => this.addToCart(product.id)}
                            className="fb-btn add-to-cart fb-btn-top"
                          >
                            {' '}
                            {this.props.buttonLoading ? T.adding : productAvailable ? T.add_to_cart : T.out_of_stock}
                          </button>
                          <button
                            type="button"
                            onClick={() => this.addToWishlist(product)}
                            style={{
                              width: 45,
                              height: 45,
                            }}
                            className="fb-btn add-to-cart fb-btn-top ml-3 wishlistBtn"
                          >
                            <i className="ion-heart" />
                          </button>
                        </div>
                      </div>
                      {typeof p.inventory === 'undefined' && (
                        <span className="product-checking-availability pt-5">
                          <i className="fa fa-spinner fa-spin text-primary mr-2" />
                          {T.checking_inventory}
                        </span>
                      )}
                      {productAvailable && typeof p.inventory !== 'undefined' && (
                        <span className="product-availability pt-5">{T.in_stock}</span>
                      )}
                      {!productAvailable && typeof p.inventory !== 'undefined' && (
                        <span className="product-not-availability pt-5">{T.out_stock}</span>
                      )}
                      {wantMoreQuantity && (
                        <div style={{ clear: 'both' }}>
                          <span>{`${T.max_quantity_exceeded} ${product.inStock}`}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    buttonLoading: state.cart.buttonLoading,
    cartProducts: state.cart.products,
    selectedLanguage: state.language.selectedLanguage,
    selectedCurrency: state.currency.selectedCurrency,
    vatType: state.vat.type,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (product, lang) => dispatch(addToCart(product, lang)),
    doAddToWishlist: (product, lang) => dispatch(addProductToWishList(product, lang)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductModal)
