import loMap from 'lodash/map'
import coreAPI from '.'
import qs from 'query-string'

export function getMenuItems(langCode = 'en') {
  return coreAPI.get('/api/menu-items', { headers: { 'X-LangCode': langCode } })
}

export function getMenuItemDetails(id, langCode = 'en') {
  return coreAPI.get(`/api/menu-items/${id}`, {
    headers: { 'X-LangCode': langCode },
  })
}

export function getMenuItemProducts(id, { page = 1, pageSize = 20, ...query }, langCode = 'en') {
  const q = qs.stringify({ page, pageSize, ...query })

  return coreAPI.get(`/api/menu-items/${id}/products?${q}`, {
    headers: { 'X-LangCode': langCode },
  })
}

export function getSecondMenuItemDetails(id, id2, langCode = 'en') {
  return coreAPI.get(`/api/menu-items/${id}/${id2}`, {
    headers: { 'X-LangCode': langCode },
  })
}

export function getSecondMenuItemProducts(id, id2, query, langCode = 'en') {
  const q = loMap(query, (v, k) => v && `${k}=${v}`)
    .filter(Boolean)
    .join('&')

  return coreAPI.get(`/api/menu-items/${id}/${id2}/products?${q}`, {
    headers: { 'X-LangCode': langCode },
  })
}

export function getThirdMenuItemDetails(id, id2, id3, langCode = 'en') {
  return coreAPI.get(`/api/menu-items/${id}/${id2}/${id3}`, {
    headers: { 'X-LangCode': langCode },
  })
}

export function getThirdMenuItemProducts(id, id2, id3, query, langCode = 'en') {
  const q = loMap(query, (v, k) => v && `${k}=${v}`)
    .filter(Boolean)
    .join('&')

  return coreAPI.get(`/api/menu-items/${id}/${id2}/${id3}/products?${q}`, {
    headers: { 'X-LangCode': langCode },
  })
}
