import coreAPI from '.'
import loMap from 'lodash/map'

const ProductCollectionService = {
  getAll(langCode = 'en') {
    return coreAPI.get(`/api/product-collections`, {
      headers: { 'X-LangCode': langCode },
    })
  },
  getOne(id, query, langCode) {
    const search = loMap(query, (value, key) => value && `${key}=${value}`)
      .filter(Boolean)
      .join('&')

    return coreAPI.get(`/api/product-collections/${id}?${search}`, {
      headers: { 'X-LangCode': langCode },
    })
  },
  getProductsByCollection(id, langCode) {
    return coreAPI.get(`/api/product-collections/${id}/products`, {
      headers: { 'X-LangCode': langCode },
    })
  },
}

export default ProductCollectionService
