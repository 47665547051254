import * as Sentry from '@sentry/node'
import { RewriteFrames } from '@sentry/integrations'

export const initSentry = () => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = []
    if (process.env.NEXT_IS_SERVER === 'true') {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: (frame) => {
            // eslint-disable-next-line no-param-reassign
            frame.filename = frame?.filename?.replace(__dirname, 'app:///')
            // eslint-disable-next-line no-param-reassign
            frame.filename = frame?.filename?.replace('.next', '_next')

            return frame
          },
        }),
      )
    }
    console.log('process.env.NEXT_PUBLIC_COMMIT_SHA', process.env.NEXT_PUBLIC_COMMIT_SHA)
    Sentry.init({
      enabled: !!process.env.NEXT_PUBLIC_COMMIT_SHA,
      integrations,
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      release: process.env.NEXT_PUBLIC_COMMIT_SHA,
      ignoreErrors: ['top.GLOBALS', 'No error message'],
      // @ts-ignore sentry node doesn't have this option however browser does
      denyUrls: [
        // Facebook flakiness
        /.+facebook.com\/signals\/iwl.js.+/,
        /.+graph\.facebook\.com/i,
        /.+connect\.facebook\.net\/.+/i,
        // tiktok
        /.+analytics.tiktok.com\/i18n\/pixel\/sdk.js.+/i,
        // yandex
        /.+mc.yandex.ru\/metrika\/tag.js.+/i,
        // gtag
        /.+googletagmanager.com\/.+/i,
        /.+google-analytics.com\/analytics.js/i,
        /.+stats.g.doubleclick.net\/.+/i,
      ],
      beforeBreadcrumb(breadcrum) {
        const blacklistRegex = /.+.yandex.ru|.+doubleclick\.net/gi
        const { category = '', data = {} } = breadcrum
        if (category?.toLowerCase() === 'xhr' && data?.url?.match(blacklistRegex)) {
          return null
        }

        return breadcrum
      },
    })
  }
}

export function captureException(err, context) {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.captureException(err, context)
  } else {
    console.error(err)
  }
}

export function captureMessage(msg, context) {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    Sentry.captureMessage(msg, context)
  } else {
    console.error(msg)
  }
}
