import React, { Component } from 'react'
import { connect } from 'react-redux'
// import Translation from '@translation'
import GoogleMapContainer from '@components/gMap'
import dynamic from 'next/dynamic'
import deliveryMapService from '@services/deliveryMapService'

const Autocomplete = dynamic(() => import('react-google-autocomplete'), {
  ssr: false,
})

class TransportModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      address: '',
      position: '',
      coordinates: [],
    }
  }

  closeModal = () => {
    window.jQuery('.close-text-modal').click()
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onPlaceSelected = async (place) => {
    const placeLat = place.geometry.location.lat()
    const placeLng = place.geometry.location.lng()

    this.setState({ position: { lat: placeLat, lng: placeLng } })
  }

  async componentDidMount() {
    const { coordinates } = await deliveryMapService.getMapCoordinates()

    this.setState({ coordinates })
  }

  render() {
    // const { selectedLanguage } = this.props
    // const T = Translation(selectedLanguage)
    console.log(this.state.coordinates)
    return (
      <div className="modal fade modal-wrapper" id="transportModal">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body text-center">
              <h4>Cena dopravy i nabídka zboží se liší podle vaší lokality.</h4>
              <h3 className="font-weight-bold">Kam vám nákup dovezeme?</h3>
              <div className="mt-5">
                <div className="d-flex items-center justify-content-center contact-form">
                  <div className="row w-100 form-group">
                    <div className="col-md-12 mt-5">
                      <p className="text-left mb-1">Zadejte prosím adresu doručení:</p>
                    </div>
                    <div className="col-md-12">
                      <Autocomplete
                        id="autocomplete_google_input_billing"
                        onPlaceSelected={this.onPlaceSelected}
                        options={{
                          types: ['address'],
                          componentRestrictions: { country: 'cz' }
                        }}
                        name="address"
                        onChange={this.onChange}
                        value={this.state.address}
                        style={{
                          background: '#fff none repeat scroll 0 0',
                          border: '1px solid #e5e5e5',
                          borderRadius: 0,
                          height: '42px',
                          width: '100%',
                          padding: '0 0 0 10px',
                          color: '#a4a4a4',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.coordinates.length > 0 && (
              <div>
                <GoogleMapContainer coordinates={this.state.coordinates} position={this.state.position} selectedLanguage={this.props.selectedLanguage} />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.language.selectedLanguage,
    user: state.login.user,
  }
}

export default connect(mapStateToProps, null)(React.memo(TransportModal))
