import React from 'react'

function QuantityInput({ name, value, onChange, onBlur, onIncrementOrDecrement }) {
  return (
    <div className="cart-plus-minus d-flex align-items-center" style={{ width: '76px' }}>
      <input
        className="cart-plus-minus-box"
        type="text"
        name={name}
        style={{ width: '3rem' }}
        value={value}
        onClick={(e) => {
          e.target.select()
        }}
        onBlur={onBlur}
        onChange={onChange}
      />
      <div onClick={() => onIncrementOrDecrement('dec')} className="dec qtybutton">
        -
      </div>
      <div onClick={() => onIncrementOrDecrement('inc')} className="inc qtybutton">
        +
      </div>
    </div>
  )
}

export default React.memo(QuantityInput)
