import langEn from './en' // default language

export const SUPPORTED_LANGUAGES = {
  en: { key: 'en', name: 'English' },
  cz: { key: 'cz', name: 'Česky' },
}

export function getSelectedLang(langCode) {
  const lang = SUPPORTED_LANGUAGES[langCode]
  return lang || SUPPORTED_LANGUAGES.en
}

export async function setLang(lang = 'en') {
  console.log('setting lang ' + JSON.stringify(lang))
  if (typeof window !== 'undefined') {
    let langObj = { ...langEn }
    if (lang === 'en') {
      window.__T__ = langEn
      return
    }
    if (lang === 'cz') {
      langObj = (await import('./cz')).default
    } else {
      langObj = (await import('./de')).default
    }
    window.__T__ = { ...langEn, ...langObj }
    localStorage.setItem('__T__', JSON.stringify(window.__T__))
  }
}

function getT(lang = 'en') {
  // let langCode = lang
  // if (lang.hasOwnProperty('key')) {
  //   langCode = lang.key
  // }

  // if (langCode === 'en') {
  //   return langEn
  // }

  // if (typeof window === 'undefined') {
  //   if (langCode === 'cz') {
  //     return global.__T__CZ
  //   }
  //   if (langCode === 'de') {
  //     return global.__T__DE
  //   }
  //   return langEn
  // }

  // if (!window.__T__) {
  //   try {
  //     const t = JSON.parse(localStorage.getItem('__T__'))
  //     if (!t) {
  //       setLang(langCode)
  //     }
  //     window.__T__ = t
  //     return t
  //   } catch (err) {
  //     return langEn
  //   }
  // }

  // return window.__T__
  return langEn
}

export default getT
