import coreAPI from '.'

const CartService = {
  addToCart(product, langCode = 'en') {
    const { id, productId, units } = product

    const sessionId = localStorage.getItem('sessionId')

    return coreAPI.put(
      `/api/carts`,
      { productId: productId || id, sessionId, units },
      {
        headers: { 'X-LangCode': langCode },
      },
    )
  },

  getAllCartItems(langCode = 'en') {
    const sessionId = localStorage.getItem('sessionId')

    if (!sessionId) {
      return Promise.resolve({ cart: { products: [] } })
    }

    return coreAPI.get(`/api/carts/${sessionId}`, {
      headers: { 'X-LangCode': langCode },
    })
  },

  getFees(from) {
    console.log('>>>from', from)
    const sessionId = localStorage.getItem('sessionId')

    return coreAPI.get(`/api/carts/${sessionId}/fees`)
  },

  removeFromCart(productId, langCode = 'en') {
    const sessionId = localStorage.getItem('sessionId')

    return coreAPI.delete(`/api/carts/${sessionId}/${productId}`, {
      headers: { 'X-LangCode': langCode },
    })
  },

  clearCart() {
    const sessionId = localStorage.getItem('sessionId')

    return coreAPI.delete(`/api/carts/${sessionId}`)
  },
}

export default CartService
