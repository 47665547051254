import coreAPI from '.'
import axios from 'axios'

const UserService = {
  getSessionId() {
    return coreAPI.get(`/api/users/session-id`)
  },

  requestNewPassword({ email, lang }) {
    return coreAPI.get(`/api/users/request-new-password?email=${email}&lang=${lang}`)
  },

  resetPassword({ password, confirmPassword, uniqueId, lang }) {
    return coreAPI.post(`/api/users/reset-password?lang=${lang}`, {
      password,
      confirmPassword,
      uniqueId,
    })
  },

  createGuestCustomer(billingAddress, shippingAddress) {
    return coreAPI.put(`/api/users/guest-customers`, {
      billingAddress,
      shippingAddress,
    })
  },

  registerCustomer(data, lang) {
    return coreAPI.put(`/api/users/register?lang=${lang}`, data)
  },

  login(data) {
    return coreAPI.post(`/api/users/login`, data)
  },

  getUser: ({ token }) => {
    return coreAPI.get(`/api/users/single`, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },

  verifyResetLink({ id }) {
    return coreAPI.get(`/api/users/verify-password-request?id=${id}`)
  },

  getOrders({ token, langCode }) {
    return coreAPI.get(`/api/users/orders`, {
      headers: { Authorization: `Bearer ${token}`, 'X-LangCode': langCode },
    })
  },

  updateProfile({ data, token }) {
    return coreAPI.post(`/api/users/update`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },

  updatePassword({ data, token }) {
    return coreAPI.post(`/api/users/profile/reset-password`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },

  downloadInvoice({ orderId, token }, lang = 'en') {
    return coreAPI.get(`/api/users/orders/${orderId}/invoice`, {
      headers: { Authorization: `Bearer ${token}`, 'X-LangCode': lang },
    })
  },

  getSingleOrder({ uniqueId, token }) {
    return coreAPI.get(`/api/users/orders/${uniqueId}`, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'stream',
    })
  },

  upsertWishlist(data, lang) {
    const sessionId = localStorage.getItem('sessionId')
    const { productId, units = 1, id } = data

    return coreAPI.put(
      `/api/users/wishlist/${sessionId}`,
      {
        productId: productId || id,
        units,
      },
      {
        headers: { 'X-LangCode': lang },
      },
    )
  },

  getWishList(langCode = 'en') {
    const sessionId = localStorage.getItem('sessionId')

    return coreAPI.get(`/api/users/wishlist/${sessionId}`, {
      headers: { 'X-LangCode': langCode },
    })
  },

  removeFromWishlist(productId, lang = 'en') {
    const sessionId = localStorage.getItem('sessionId')

    return coreAPI.delete(`/api/users/wishlist/${sessionId}?productId=${productId}`, {
      headers: { 'X-LangCode': lang },
    })
  },

  setToken(token, rememberMe = false) {
    return axios.post(`/api/set-cookie`, {
      token,
      rememberMe,
    })
  },

  removeToken() {
    return axios.delete(`/api/delete-cookie`)
  },

}

export default UserService
