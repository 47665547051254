import CartService from '@services/cartService'
import loGet from 'lodash/get'
import { toast } from 'react-toastify'

export const ADD_STARTED = 'ADD_STARTED'
export const ADD_SUCCESS = 'ADD_SUCCESS'
export const ADD_FAILED = 'ADD_FAILED'
export const CART_ITEMS_STARTED = 'CART_ITEMS_STARTED'
export const CART_ITEMS_SUCCESS = 'CART_ITEMS_SUCCESS'
export const CART_ITEMS_FAILED = 'CART_ITEMS_FAILED'

export const getItemsStarted = () => {
  return {
    type: CART_ITEMS_STARTED,
  }
}

export const getItemsSuccess = (products) => {
  return {
    type: CART_ITEMS_SUCCESS,
    products,
  }
}

export const getItemsFailed = () => {
  return {
    type: CART_ITEMS_FAILED,
  }
}

export const addingStarted = () => {
  return {
    type: ADD_STARTED,
  }
}

export const addSucceeded = (products) => {
  return {
    type: ADD_SUCCESS,
    products,
  }
}

export const addFailed = (errorMessage) => {
  return {
    type: ADD_FAILED,
    errorMessage,
  }
}

export const addToCart = (product, lang) => {
  return async (dispatch) => {
    try {
      dispatch(addingStarted())
      toast.info(`Adding product to cart`)
      const {
        cart: { products },
      } = await CartService.addToCart(product, lang)
      dispatch(addSucceeded(products))
      return products
    } catch (err) {
      const msg = err.message

      dispatch(addFailed({ errorMessage: msg }))
      throw new Error(msg)
    }
  }
}

export const updateCart = (products) => {
  return (dispatch) => {
    dispatch(addSucceeded(products))
  }
}

export const removeFromCart = (id, lang) => {
  return async (dispatch) => {
    try {
      dispatch(addingStarted())
      await CartService.removeFromCart(id, lang)
      const {
        cart: { products },
      } = await CartService.getAllCartItems(lang)
      dispatch(addSucceeded(products))
    } catch (err) {
      dispatch(addFailed({ errorMessage: err.message || 'something went wrong' }))
    }
  }
}

export const getAllCartItems = (langCode) => {
  return (dispatch) => {
    dispatch(getItemsStarted())

    CartService.getAllCartItems(langCode)
      .then((res) => {
        dispatch(getItemsSuccess(res.cart.products))
      })
      .catch((err) => {
        dispatch(
          getItemsFailed({
            errorMessage: err.message || 'something went wrong',
          }),
        )
      })
  }
}
export const removeAllFromCart = () => {
  return async (dispatch) => {
    try {
      const {
        cart: { products },
      } = await CartService.clearCart()
      dispatch(addSucceeded(products))
    } catch (err) {
      dispatch(
        addFailed({
          // eslint-disable-next-line
          errorMessage: loGet(err, 'response.data.message', 'something went wrong'),
        }),
      )
      throw err
    }
  }
}
