import getConfig from 'next/config'
import loGroup from 'lodash/groupBy'
import loSize from 'lodash/size'
import moment from 'moment'

const { publicRuntimeConfig } = getConfig()
export function getBaseUrl() {
  return publicRuntimeConfig.API_URL
}

export function currencyFormatter(amount = 0, currency = 'GBP') {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  }).format(amount)
}

export function roundToTwo(num) {
  return +`${Math.round(`${num}e+2`)}e-2`
}

export function sumPriceVATUnformatted(products = [], currency) {
  const price = products.reduce((total, p) => {
    const pr = p.prices.find((v) => v.currency?.identifier === currency.identifier)
    if (pr) {
      return total + +pr.priceWithVat * p.units * p.amountInPackage
    }
    return total + +p.priceWithVat * p.units * p.amountInPackage
  }, 0)

  return roundToTwo(price)
}

export function sumPriceVAT(products = [], currency, totalFee = 0) {
  const price = sumPriceVATUnformatted(products, currency)

  return currencyFormatter(price + totalFee, currency.identifier)
}

export function sumPriceWithoutVAT(products = [], currency) {
  const price = products.reduce((total, p) => {
    const pr = p.prices.find((v) => v.currency?.identifier === currency.identifier)

    if (pr) {
      return total + +pr.priceWithoutVat * p.units * p.amountInPackage
    }

    return total + +p.priceWithoutVat * p.units * p.amountInPackage
  }, 0)

  return currencyFormatter(price, currency.identifier)
}

export function sumVAT(products = [], currency = { identifier: 'GBP' }, feeVAT = 0) {
  const price = products.reduce((total, p) => {
    const pr = p.prices.find((v) => v.currency?.identifier === currency.identifier)
    if (pr) {
      return total + (+pr.priceWithVat - +pr.priceWithoutVat) * p.units * p.amountInPackage
    }
    return total + (+p.priceWithVat - +p.priceWithoutVat) * p.units * p.amountInPackage
  }, 0)

  return currencyFormatter(price + feeVAT, currency.identifier)
}

export function getSumForProduct(product, currency, units, vatType) {
  if (!currency || !product.prices) {
    return ''
  }

  const priceObj = product.prices.find((p) => p.currency.identifier === currency.identifier)

  let price = null

  if (vatType === 'included') {
    price = product.amountInPackage * units * priceObj.priceWithVat
  } else {
    price = product.amountInPackage * units * priceObj.priceWithoutVat
  }

  const priceStr = currencyFormatter(price, currency.identifier)

  return priceStr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function getPrice(product, currency, oldPrice = false, vatType = 'included') {
  if (!currency || !product.prices) {
    return ''
  }

  const priceObj = product.prices.find((p) => p.currency.identifier === currency.identifier)

  let price = Number(oldPrice ? priceObj.oldPriceWithVat : priceObj.priceWithVat)
  if (vatType === 'excluded') {
    price = oldPrice ? priceObj.oldPriceWithoutVat : priceObj.priceWithoutVat
  }
  const priceStr = currencyFormatter(price, currency.identifier)
  return priceStr.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function calculateDiscount(product) {
  if (!product) {
    return null
  }

  return `${
    product.oldPriceWithVat
      ? (((product.oldPriceWithVat - product.priceWithVat) / product.oldPriceWithVat) * 100).toFixed(0)
      : 0
  }%`
}

export function getSelectedCurrency(selectedCurrency) {
  return selectedCurrency ? selectedCurrency.symbol : ''
}

export function getCurrencyName(currency) {
  if (!currency) {
    return ''
  }

  return `${currency.identifier} ${currency.symbol}`
}

export function getCurrencySymbol(currency) {
  if (!currency) {
    return ''
  }

  return currency.symbol
}

export function getPrimaryImage(images = []) {
  if (!images.length) {
    return
  }

  for (let i = 0; i < images.length; i += 1) {
    if (images[i].isPrimary) {
      return images[i].imageUrl
    }
  }
  return images[0] && images[0].imageUrl
}

export function processProductDetails(p, lang = 'en') {
  const { productTranslations = [], compositions, soldCount, inventory } = p

  const createdAt = moment(p.createdAt).startOf('day')
  const now = moment().startOf('day')
  const isNew = now.diff(createdAt, 'day') <= 7

  let trans = productTranslations[0]
  if (productTranslations.length !== 1) {
    trans = productTranslations.find((v) => v.lang === lang)
  }

  let compos = loGroup(compositions, 'lang')[lang] || []
  if (!compos.length) {
    compos = compositions
  }

  if (!trans) {
    trans = { slug: p.id }
  }

  const inStock = inventory - soldCount

  return {
    ...p,
    ...trans,
    // name: [p.brand?.name, trans.name || p.name].filter(Boolean).join(' - '),
    compositions: compos,
    available: typeof inventory === 'undefined' ? p.available : inStock > 0,
    inStock: +inStock,
    isNew,
  }
}

export function toggleCart() {
  document.getElementById('myCartContainer').click()
}

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  toggleCart()
}

export const scrollFormToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop

  if (c > 0) {
    window.requestAnimationFrame(scrollFormToTop)
    window.scrollTo(0, c - c / 8)
  }
}

export default {
  getBaseUrl,
  sumPriceVAT,
  sumPriceWithoutVAT,
  getSelectedCurrency,
  getPrice,
  calculateDiscount,
  getSumForProduct,
  getCurrencyName,
  getCurrencySymbol,
  getPrimaryImage,
  getProductTranslation: processProductDetails,
}

export function getCategoryTranslation(category, lang = 'en') {
  const { translations = [] } = category
  if (!translations.length) {
    return category
  }

  let trans = translations[0]
  if (translations.length !== 1) {
    trans = translations.find((v) => v.lang === lang)
  }

  if (!trans) {
    return category // use id as slug
  }

  return { ...category, ...trans, slug: trans.slug || category.id }
}

export function processCollectionDetails(c = {}, langCode = 'en') {
  const { collectionTranslations = [] } = c
  let trans = collectionTranslations[0] || {}
  if (loSize(collectionTranslations) > 1) {
    trans = collectionTranslations.find((t) => t.lang === langCode) || {}
  }
  return { ...c, ...trans }
}

export function processMenuItemDetails(langCode = 'en') {
  return (menu = {}) => {
    const { translations = [] } = menu
    let trans = translations[0] || {}
    if (loSize(translations) > 1) {
      const { id, ...transInfo } = translations.find((t) => t.lang === langCode) || {}
      trans = transInfo
    }
    return { ...menu, ...trans }
  }
}

export function processShippingFee(fee = {}, currency = { identifier: 'GBP' }) {
  if (fee.currencyCode !== currency.identifier) {
    const totalAmountInCZK = fee.price * fee.conversionRate
    return totalAmountInCZK * currency.exchangeRateToCzk || 0
  }
  return fee.price
}

export function processShippingFeeDisplay(fee = {}, currency = { identifier: 'GBP' }) {
  return currencyFormatter(processShippingFee(fee, currency), currency.identifier)
}

export function processShippingFeeWithoutVAT(fee = {}, currency = { identifier: 'GBP' }) {
  let { price } = fee
  if (fee.currencyCode !== currency.identifier) {
    const totalAmountInCZK = fee.price * fee.conversionRate
    price = totalAmountInCZK * currency.exchangeRateToCzk || 0
  }
  return price - (price * 21) / 121
}
