import ProductCollectionService from '@services/productCollectionService'

export const productCollectionActionTypes = {
  GET_ALL_PRODUCT_COLLECTIONS: 'GET_ALL_PRODUCT_COLLECTIONS',
  GET_ALL_PRODUCT_COLLECTIONS_SUCCESS: 'GET_ALL_PRODUCT_COLLECTIONS_SUCCESS',
  GET_ALL_PRODUCT_COLLECTIONS_FAILURE: 'GET_ALL_PRODUCT_COLLECTIONS_FAILURE',
  GET_ONE_PRODUCT_COLLECTION: 'GET_ONE_PRODUCT_COLLECTION',
  GET_ONE_PRODUCT_COLLECTION_SUCCESS: 'GET_ONE_PRODUCT_COLLECTION_SUCCESS',
  GET_ONE_PRODUCT_COLLECTION_FAILURE: 'GET_ONE_PRODUCT_COLLECTION_FAILURE',
}

export const getAllProductCollectionsFailure = (error) => {
  return {
    type: productCollectionActionTypes.GET_ALL_PRODUCT_COLLECTIONS_FAILURE,
    error,
  }
}

export const getAllProductCollectionsSuccess = (response) => {
  return {
    type: productCollectionActionTypes.GET_ALL_PRODUCT_COLLECTIONS_SUCCESS,
    response,
  }
}

export const getAllProductCollections = () => (dispatch) => {
  ProductCollectionService
    .getAll()
    .then((res) => {
      dispatch(getAllProductCollectionsSuccess(res.productCollections))
    })
    .catch((error) => dispatch(getAllProductCollectionsFailure(error)))
}


export const getOneProductCollectionSuccess = (response) => {
  return {
    type: productCollectionActionTypes.GET_ONE_PRODUCT_COLLECTION_SUCCESS,
    response,
  }
}

export const getOneProductCollectionFailure = (error) => {
  return {
    type: productCollectionActionTypes.GET_ONE_PRODUCT_COLLECTION_FAILURE,
    error,
  }
}

export const getOneProductCollection = (id) => (dispatch) => {
  ProductCollectionService
    .getOne(id)
    .then((res) => {
      dispatch(getOneProductCollectionSuccess(res.productCollections))
    })
    .catch((error) => getOneProductCollectionFailure(error))
}

