import UserService from '@services/userService'

export const GET_WISH_LIST_SUCCESS = 'GET_WISH_LIST_SUCCESS'
export const ADD_WISH_LIST_SUCCESS = 'GET_WISH_LIST_SUCCESS'

export function getWishList(langCode) {
  return async (dispatch) => {
    try {
      const { wishlistProducts } = await UserService.getWishList(langCode)
      dispatch({ type: GET_WISH_LIST_SUCCESS, list: wishlistProducts })
    } catch (err) {
      throw err
    }
  }
}

export function addProductToWishList(product, lang) {
  return async (dispatch) => {
    try {
      await UserService.upsertWishlist(product, lang)

      const { wishlistProducts } = await UserService.getWishList(lang)

      dispatch({ type: GET_WISH_LIST_SUCCESS, list: wishlistProducts })
    } catch (err) {
      throw err
    }
  }
}

export function removeProductFromWishList(productId, lang) {
  return async (dispatch) => {
    try {
      await UserService.removeFromWishlist(productId, lang)

      const { wishlistProducts } = await UserService.getWishList(lang)

      dispatch({ type: GET_WISH_LIST_SUCCESS, list: wishlistProducts })
    } catch (err) {
      throw err
    }
  }
}
