import coreAPI from '.'
import loMap from 'lodash/map'

const ProductService = {
  searchProducts(query = '', others = {}, langCode = 'en') {
    const options = {
      q: query,
      ...others,
    }

    const mappedOther = loMap(options, (value, key) => value && `${key}=${value}`)
      .filter(Boolean)
      .join('&')
    console.log('mappedOther', mappedOther)
    return coreAPI.get(`/api/products?${mappedOther}`, {
      headers: { 'X-LangCode': langCode },
    })
  },

  getOne(id, langCode = 'en', token) {
    return coreAPI.get(`/api/products/${id}`, {
      headers: { 'X-LangCode': langCode, Authorization: `Bearer ${token}` },
    })
  },
  getReviews(id) {
    return coreAPI.get(`/api/reviews/?productId=${id}`)
  },
  createReviews(data, token) {
    return coreAPI.put(`/api/reviews`, data, {
      headers: { Authorization: `Bearer ${token}` },
    })
  },
  getCurrencies() {
    return coreAPI.get(`/api/currencies`)
  },

  similarProducts(productId, langCode = 'en') {
    return coreAPI.get(`/api/products/${productId}/similar-products`, {
      headers: { 'X-LangCode': langCode },
    })
  },
}

export default ProductService
