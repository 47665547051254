export const SET_CURRENCY = 'SET_CURRENCY'
export const SET_CURRENCIES = 'SET_CURRENCIES'

export const setCurrency = (payload) => {
  return {
    type: SET_CURRENCY,
    selectedCurrency: payload,
  }
}

export const setCurrencyList = (payload) => {
  return {
    type: SET_CURRENCIES,
    currencies: payload,
  }
}

export const onSetCurrency = (data) => {
  return (dispatch) => {
    dispatch(setCurrency(data))
  }
}
