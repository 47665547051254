import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Router, { useRouter } from 'next/router'

import Translation from '@translation'
import { normalizeText } from 'helpers/strings'

function SearchBox() {
  const [searchText, setSearchText] = useState('')
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage)
  const { query } = useRouter()

  useEffect(() => {
    setSearchText(query.q || '')
  }, [query.q])

  const onChange = useCallback((e) => setSearchText(e.target.value), [])

  const onSearchProducts = useCallback(
    (e) => {
      e.preventDefault()

      if (!searchText) {
        return
      }

      Router.push(
        {
          pathname: '/[lang]/products',
          query: {
            page: 1,
            pageSize: 15,
            q: normalizeText(searchText),
          },
        },
        {
          pathname: `/${selectedLanguage.key}/products`,
          query: {
            page: 1,
            pageSize: 15,
            q: normalizeText(searchText),
          },
        },
      )
    },
    [searchText, selectedLanguage.key],
  )

  const T = Translation(selectedLanguage) || {}

  return (
    <div className="responsive-nav">
      <div className="hm-searchbox">
        <form onSubmit={onSearchProducts} className="w-100">
          <input
            placeholder={T.what_are_you_looking_for}
            className="w-100"
            id="navbar-searchbox"
            onChange={onChange}
            value={searchText}
          />
          <button type="submit" className="fb-search_btn">
            <i className="fa fa-search" />
          </button>
        </form>
      </div>
      <div className="mobile-delivery d-none">
        <li className="delivery">
          <img src="/assets/images/truck.png" alt="truck" />
          <div>
            <p className="text-red">
              {T.in} <br /> 24h
            </p>
          </div>
        </li>
      </div>
    </div>
  )
}

export default React.memo(SearchBox)
