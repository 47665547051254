import React, { Component } from 'react'
import Link from 'next/link'
import Router from 'next/router'
import { connect } from 'react-redux'
import { onSignOut } from '@store/actions/login'
import { onSetCurrency } from '@store/actions/currency'
import { onSetLanguage } from '@store/actions/language'
import Translation, { setLang } from '@translation/index'
import { initGA } from '@helpers/analytics'
import { setVATType } from '@store/actions/vat'
import { toast } from 'react-toastify'
import TransportModal from '@components/ui/transportModal'

class AccountInfo extends Component {
  state = {
    dropdownOpened: false,
    languages: [
      {
        name: 'English',
        key: 'en',
      },
      {
        name: 'Česky',
        key: 'cz',
      },
    ],
    languageDropdownOpened: false,
    vatDropDownOpen: false,
  }

  signOut = async () => {
    try {
      const { selectedLanguage, doSignOut } = this.props
      const T = Translation(selectedLanguage)
      await doSignOut()
      Router.reload()
      toast.success(T.signed_out_successfully)
    } catch (err) {
      console.log(err)
      toast.error(err.message)
    }
  }

  async componentDidMount() {
    if (!window.GA_INITIALIZED) {
      initGA()
      window.GA_INITIALIZED = true
    }
    const { selectedLanguage } = this.props
    await setLang(selectedLanguage.key)
  }

  setCurrency = (currency) => {
    document.cookie = `selectedCurrency=${currency.identifier};path=/`
    this.props.setCurrency(currency)
  }

  setVatType = (type = 'included') => {
    document.cookie = `VAT=${type};path=/`
    this.props.doSetVATType(type)
  }

  setLanguage = async (language) => {
    const { selectedLanguage } = this.props
    const lang = {
      ...language,
      name: encodeURI(language.name),
    }
    document.cookie = `lang=${JSON.stringify(lang)}; path=/;`

    const languageCurrency = {
      cz: 'CZK',
      en: 'EUR',
    }
    const currency = this.props.currencies.find(
      (c) => c.identifier === languageCurrency[language.key],
    )

    if (currency) {
      this.setCurrency(currency)
    }
    const {
      location: { pathname, search },
    } = window
    await this.props.setLanguage(language)
    window.location.href = `${pathname.replace(selectedLanguage.key, language.key)}${search}`

    const T = Translation(language)
    toast.success(T.page_translated_successfully)

    this.setState((prevState) => ({
      languageDropdownOpened: !prevState.languageDropdownOpened,
    }))
  }

  toggleDropdown = () => {
    const { dropdownOpened } = this.state

    this.setState({
      dropdownOpened: !dropdownOpened,
    })
  }

  toggleLanguageDropdown = () => {
    this.setState((prevState) => ({
      languageDropdownOpened: !prevState.languageDropdownOpened,
    }))
  }

  toggleVATDropdown = () => {
    this.setState((prevState) => ({
      vatDropDownOpen: !prevState.vatDropDownOpen,
    }))
  }

  render() {
    const { selectedLanguage, selectedCurrency, user, vatType, currencies } = this.props
    const { name: langName } = selectedLanguage
    const T = Translation(selectedLanguage) || {}

    const { languages, languageDropdownOpened, dropdownOpened } = this.state

    return (
      <div className="row">
        <div className="col-lg-5 col-md-6">
          <div className="top-selector-wrapper">
            <ul className="single-top-selector d-flex align-items-center">
              <li className="language list-inline-item">
                <div className="btn-group">
                  <button
                    type="button"
                    onClick={this.toggleLanguageDropdown}
                    className="dropdown-toggle"
                  >
                    {decodeURI(langName)} <i className="fa fa-caret-down"></i>
                  </button>
                  <div className={languageDropdownOpened ? 'dropdown-menu open' : 'dropdown-menu'}>
                    <ul>
                      {languages.map((l) => {
                        return (
                          <li key={l.key} onClick={() => this.setLanguage(l)}>
                            <a>{l.name}</a>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </li>
              <li className="language list-inline-item">
                <div className="btn-group">
                  <button
                    type="button"
                    onClick={() => this.toggleDropdown()}
                    className="dropdown-toggle"
                  >
                    {' '}
                    {selectedCurrency.identifier} <i className="fa fa-caret-down"></i>
                  </button>
                  <div className={dropdownOpened ? 'dropdown-menu open' : 'dropdown-menu'}>
                    <ul>
                      {currencies.map((c) => {
                        return (
                          <li key={c.identifier}>
                            <a onClick={() => this.setCurrency(c)}>{c.identifier}</a>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </li>
              <li className="currency list-inline-item">
                <div
                  className="custom-control custom-switch"
                  style={{ fontSize: 13, lineHeight: '24px' }}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="vatSwitcher"
                    checked={vatType === 'included'}
                    onChange={(e) =>
                      e.target.checked ? this.setVatType('included') : this.setVatType('excluded')
                    }
                    style={{ outline: 0 }}
                  />
                  <label className="custom-control-label text-white" htmlFor="vatSwitcher">
                    {vatType === 'included' ? T.vat_included : T.vat_excluded}
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-7 col-md-6">
          <div className="header-top-right d-flex justify-content-end">
            <ul className="user-block list-inline">
              <li className="list-inline-item color-white language">
                <div
                  className="d-flex align-items-center"
                  data-toggle="modal"
                  data-target="#transportModal"
                  style={{ cursor: 'pointer' }}
                >
                  <img style={{ width: '40px' }} src="/assets/images/truck.png" alt="truck icon" />
                  <label
                    className="text-white ml-1 mb-0" style={{ fontSize: '13px', marginTop: '2px', cursor: 'pointer' }}
                  >
                    {T.transport}
                  </label>
                </div>
              </li>
              {user ? (
                <li onClick={this.signOut}>
                  <Link href="/">
                    <a>{T.sign_out}</a>
                  </Link>
                </li>
              ) : (
                <li>
                  <Link
                    href="/[lang]/login"
                    as={{
                      pathname: `/${selectedLanguage.key}/login`,
                      query: {
                        redirectBack: true,
                      },
                    }}
                  >
                    <a className="pl-2">
                      {T.sign_in} | {T.register}
                    </a>
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
        <TransportModal/>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    selectedCurrency: state.currency.selectedCurrency,
    currencies: state.currency.currencies,
    selectedLanguage: state.language.selectedLanguage,
    vatType: state.vat.type,
    location: { pathname: '/' },
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doSignOut: () => dispatch(onSignOut()),
    setCurrency: (currency) => dispatch(onSetCurrency(currency)),
    setLanguage: (language) => dispatch(onSetLanguage(language)),
    doSetVATType: (type) => dispatch(setVATType(type)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountInfo)
