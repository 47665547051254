import * as actionTypes from '../actions/vat'

const initialState = {
  type: 'included',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_VAT_TYPE:
      return {
        ...state,
        type: action.vatType,
      }
    default:
      return state
  }
}

export default reducer
