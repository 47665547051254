import { combineReducers } from 'redux'
import cart from '../reducers/cart'
import login from '../reducers/login'
import currency from '../reducers/currency'
import language from '../reducers/language'
import modal from '../reducers/modal'
import categoryReducer from './category'
import wishlistReducer from './wishlist'
import vat from './vat'

const rootReducer = combineReducers({
  cart,
  login,
  currency,
  language,
  modal,
  categoryReducer,
  wishlist: wishlistReducer,
  vat,
})

export default rootReducer
