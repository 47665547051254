import React from 'react'
import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { Provider } from 'react-redux'
import App from 'next/app'
import reducers from './reducers'
import parseCookie from 'helpers/cookies'
import { setLanguage } from './actions/language'
import { setCurrency } from './actions/currency'
import { loginSuccess } from './actions/login'
import { getMenuItems } from '@services/menu-items-service'
import { setVATType } from './actions/vat'
import { getSelectedLang } from '@translation/'

// CREATING INITIAL STORE
const initializeStore = (initState = {}) => {
  const store = createStore(
    reducers,
    initState,
    composeWithDevTools(applyMiddleware(thunkMiddleware)),
  )

  // IF REDUCERS WERE CHANGED, RELOAD WITH INITIAL STATE
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const createNextReducer = require('./reducers').default

      store.replaceReducer(createNextReducer(initState))
    })
  }

  return store
}

let reduxStore = null
const getOrInitializeStore = (initialState) => {
  // Always make a new store if server, otherwise state is shared between requests
  if (typeof window === 'undefined') {
    return initializeStore(initialState)
  }

  // Create store if unavailable on the client and set it on the window object
  if (!reduxStore) {
    reduxStore = initializeStore(initialState)
  }

  return reduxStore
}

// eslint-disable-next-line import/prefer-default-export
export const withReduxStore = (PageComponent, { ssr = true } = {}) => {
  const WithRedux = ({ initialReduxState, ...props }) => {
    const store = getOrInitializeStore(initialReduxState)
    return (
      <Provider store={store}>
        <PageComponent {...props} />
      </Provider>
    )
  }

  // Make sure people don't use this HOC on _app.js level
  if (process.env.NODE_ENV !== 'production') {
    const isAppHoc = PageComponent === App || PageComponent.prototype instanceof App
    if (isAppHoc) {
      throw new Error('The withRedux HOC only works with PageComponents')
    }
  }

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== 'production') {
    const displayName = PageComponent.displayName || PageComponent.name || 'Component'

    WithRedux.displayName = `withRedux(${displayName})`
  }

  if (ssr || PageComponent.getInitialProps) {
    WithRedux.getInitialProps = async (context) => {
      const cookies = parseCookie(context)
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const store = getOrInitializeStore()
      const storeState = store.getState()
      const selectedLanguage = getSelectedLang(context.ctx.query.lang)

      if (cookies.token) {
        try {
          const user = JSON.parse(cookies.token)
          store.dispatch(loginSuccess({ user }))
        } catch (err) {
          console.error(err)
        }
      }

      const languageCurrency = {
        cz: 'CZK',
        en: 'EUR',
      }
      const currencyCode = cookies.selectedCurrency || languageCurrency[selectedLanguage.key]
      const { currencies = [], selectedCurrency } = storeState.currency
      const currency = currencies.find((c) => c.identifier === currencyCode) || selectedCurrency

      store.dispatch(setVATType(cookies.VAT || 'included'))
      store.dispatch(setLanguage(selectedLanguage))
      store.dispatch(setCurrency(currency))

      const { menuItems } = await getMenuItems(selectedLanguage.key)
      store.dispatch({ type: 'GET_MENU_ITEMS_OK', menuItems })

      // Provide the store to getInitialProps of pages
      context.reduxStore = store
      // Run getInitialProps from HOCed PageComponent
      const pageProps =
        typeof PageComponent.getInitialProps === 'function'
          ? await PageComponent.getInitialProps(context)
          : {}

      // Pass props to PageComponent
      return {
        ...pageProps,
        initialReduxState: store.getState(),
      }
    }
  }

  return WithRedux
}
