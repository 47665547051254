// import cookieParser from 'cookie-parser'

import redirect from './redirect'

export default function parseCookie(context) {
  if (context && context.ctx && context.ctx.req) {
    return context.ctx.req.cookies || {}
  }
  return (document.cookie || '').split(';').reduce((fin, c) => {
    const [k, v] = c.trim().split('=')
    return { ...fin, [k]: v }
  }, {})
}

export function authGuard(req, res) {
  if (!req.cookies.token) {
    redirect('/', { req, res })
  }
  try {
    const user = JSON.parse(req.cookies.token)
    if (!user.token) {
      redirect('/', { req, res })
    }
  } catch (err) {}
}
