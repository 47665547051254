export default function categoryReducer(
  state = { list: [], menuItems: [] },
  action,
) {
  switch (action.type) {
    case 'GET_CATEGORY_LIST_OK':
      return { list: action.list }
    case 'GET_MENU_ITEMS_OK':
      return { ...state, menuItems: action.menuItems }
    default:
      return state
  }
}
