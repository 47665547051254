import React from 'react'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const Logo = () => {
  const { selectedLanguage } = useSelector((state) => state.language)
  return (
    <div className="col-lg-3 col-md-6 col-sm-10 col-10 mr-auto ml-auto">
      <div className="logo">
        <Link href="/[lang]" as={`/${selectedLanguage.key}`}>
          <a>
            <LazyLoadImage
              src="/assets/images/logo/batibau/BatiBau_logo_png-White_LONG_VERSION.png"
              alt="FB's Logo"
            />
          </a>
        </Link>
      </div>
    </div>
  )
}

export default Logo
