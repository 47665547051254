import { setLang } from '@translation'

export const SET_VAT_TYPE = 'SET_VAT_TYPE'

export const setVATType = (vatType = 'included') => {
  return {
    type: SET_VAT_TYPE,
    vatType,
  }
}
