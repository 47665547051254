import * as loginActions from '../actions/login'

const initialState = {
  errorMessage: null,
  registerLoading: false,
  loginLoading: false,
  user: null,
  sessionId: undefined,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case loginActions.USER_SESSION_ID:
      return { ...state, sessionId: action.sessionId }
    case loginActions.REGISTER_STARTED:
      return {
        ...state,
        registerLoading: true,
        errorMessage: null,
      }
    case loginActions.REGISTER_SUCCESS:
      return {
        ...state,
        registerLoading: false,
        user: action.user,
      }
    case loginActions.REGISTER_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        registerLoading: false,
      }

    case loginActions.LOGIN_STARTED:
      return {
        ...state,
        loginLoading: true,
        errorMessage: null,
      }
    case loginActions.LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        user: action.user,
      }
    case loginActions.LOGIN_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loginLoading: false,
      }
    case loginActions.SIGN_OUT:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loginLoading: false,
        user: null,
      }
    default:
      return state
  }
}

export default reducer
