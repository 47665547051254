import React, { useEffect } from 'react'
import SearchBox from './header/searchBox'
import AccountInfo from './header/accountInfo'
import Logo from './header/logo'
import Cart from './header/cart'
import NavigationBar from './navigation'
import { setLang } from '@translation/'

function Header({ pathname, langCode }) {
  useEffect(() => {
    setLang(langCode)
  }, [])

  return (
    <header className="bg-midnight">
      <div className="header-top">
        <div className="container header-container">
          <div className="header-top-nav">
            <AccountInfo />
          </div>
        </div>
      </div>
      <div className="header-middle">
        <div className="container logo-nav">
          <div className="row align-items-center">
            <Logo />
            <div className="col-lg-9">
              <div className="header-middle-right">
                <SearchBox />
                <Cart />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavigationBar pathname={pathname} />
    </header>
  )
}

export default React.memo(Header)
