/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-unknown-property */

import React, { Component } from 'react'
import Link from 'next/link'
import { connect } from 'react-redux'
import equal from 'fast-deep-equal'
import { getAllCartItems, removeFromCart, updateCart, addToCart } from '@store/actions/cart'
import Translation from '@translation'
import Loader from '../../loader'
import { getWishList } from '@store/actions/wishlist'
import {
  processProductDetails,
  getSumForProduct,
  getPrimaryImage,
  sumPriceVAT,
  processShippingFee,
  currencyFormatter,
  roundToTwo,
} from 'helpers/helperFuncs'
import CartService from '@services/cartService'
import { LazyLoadImage } from 'react-lazy-load-image-component'

class Cart extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cartDisplayed: false,
      accountDisplayed: false,
      quantities: {},
      shippingFee: 0,
    }
  }

  async componentWillReceiveProps(props) {
    if (props.products && !equal({ products: this.props.products }, { products: props.products })) {
      const { quantities } = this.state
      for (const p of props.products) {
        quantities[p.id] = roundToTwo(p.units * p.amountInPackage)
      }
      const { shippingFee } = await CartService.getFees('cart header')
      this.setState({ shippingFee: processShippingFee(shippingFee, props.selectedCurrency), quantities })
    }
  }

  getSelectedCurrency() {
    return this.props.selectedCurrency?.symbol || ''
  }

  async onBlur(e, productId) {
    const products = [...this.props.products]
    let cartItem = null

    for (let i = 0; i < products.length; i++) {
      if (products[i].id === productId) {
        const p = processProductDetails(products[i])

        if (p.units > 0 && p.units <= p.inStock) {
          cartItem = {
            units: Math.ceil(Number(e.target.value) / p.amountInPackage),
            productId,
          }
          products[i] = p

          const { quantities } = this.state
          quantities[p.id] = roundToTwo(cartItem.units * p.amountInPackage)

          this.setState({
            quantities,
          })
        }

        break
      }
    }

    if (cartItem) {
      await this.props.doUpdateCart(products, this.props.selectedLanguage.key)
      await this.props.doAddToCart(cartItem, this.props.selectedLanguage.key)
    }
  }

  componentDidMount() {
    const { selectedLanguage } = this.props
    this.props.getAllCartItems(selectedLanguage.key)
    this.props.doGetWishlist(selectedLanguage.key)
  }

  async incrementOrDecrement(type, productId) {
    const products = [...this.props.products]
    let cartItem = null

    for (let i = 0; i < products.length; i++) {
      if (products[i].id === productId) {
        const p = processProductDetails(products[i])

        if (type === 'dec') {
          p.units -= 1
        }

        if (type === 'inc') {
          p.units += 1
        }

        if (p.units > 0 && p.units <= p.inStock) {
          cartItem = { units: p.units, productId }
          products[i] = p

          const { quantities } = this.state
          quantities[p.id] = roundToTwo(p.units * p.amountInPackage)

          this.setState({
            quantities,
          })
        }

        break
      }
    }

    if (cartItem) {
      await this.props.doUpdateCart(products, this.props.selectedLanguage.key)
      await this.props.doAddToCart(cartItem, this.props.selectedLanguage.key)
    }
  }

  removeProduct(id) {
    const lang = this.props.selectedLanguage.key
    this.props.removeFromCart(id, lang)
  }

  closePopups = () => {
    this.setState({ cartDisplayed: false, accountDisplayed: false })
  }

  getQuantity = (unitName, amountInPackage, units) => {
    if (unitName && amountInPackage) {
      if (unitName === 'm²' || unitName === 'm³') {
        return ` = ${roundToTwo(amountInPackage * units)} ${unitName}`
      }
    }
  }

  render() {
    const { vatType, selectedCurrency, selectedLanguage, products = [] } = this.props
    const { cartDisplayed, accountDisplayed, shippingFee } = this.state
    const T = Translation(selectedLanguage)

    return (
      <>
        <div
          id="cartWrapper"
          onClick={this.closePopups}
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            zIndex: 9,
            display: cartDisplayed || accountDisplayed ? 'block' : 'none',
          }}
        />
        <ul className="hm-menu" ref={this.setWrapperRef}>
          <li className="hm-minicart">
            <div
              className="text-white text-center icon-container"
              onClick={() =>
                this.setState({
                  accountDisplayed: !accountDisplayed,
                  cartDisplayed: false,
                })
              }
            >
              <span className="fa fa-user" />
              <p className="nav-item-text">{T.account}</p>
            </div>
            {this.props.user ? (
              <div
                className="minicart"
                style={{
                  display: this.state.accountDisplayed ? 'block' : 'none',
                  left: 0,
                }}
                id="account"
              >
                <ul className="minicart-product-list">
                  <h6 className="mb-2 minicart-product-list-item">
                    <Link href="/[lang]/profile" as={`/${selectedLanguage.key}/profile`}>
                      <a onClick={() => this.setState({ accountDisplayed: false })}>{T.profile}</a>
                    </Link>
                  </h6>
                  <p className="minicart-product-list-item">
                    <Link href="/[lang]/orders" as={`/${selectedLanguage.key}/orders`}>
                      <a onClick={() => this.setState({ accountDisplayed: false })}>{T.orders}</a>
                    </Link>
                  </p>
                </ul>
              </div>
            ) : (
              <div
                className="minicart"
                style={{
                  display: this.state.accountDisplayed ? 'block' : 'none',
                  left: 0,
                }}
                id="account"
                onClick={() => this.setState({ accountDisplayed: false })}
              >
                <ul className="minicart-product-list">
                  <h6 className="minicart-product-list-item">
                    <Link
                      href="/[lang]/login"
                      as={{
                        pathname: `/${selectedLanguage.key}/login`,
                        query: {
                          redirectBack: true,
                        },
                      }}
                    >
                      <a onClick={() => this.setState({ accountDisplayed: false })}>
                        {T.sign_in} | {T.register}
                      </a>
                    </Link>
                  </h6>
                </ul>
              </div>
            )}
          </li>
          <li className="hm-minicart">
            <div className="text-white text-center icon-container">
              <Link href="/[lang]/wishlist" as={`/${selectedLanguage.key}/wishlist`}>
                <i className="fa fa-heart" />
              </Link>
              <span className="item-count">{this.props.wishlist.length}</span>
              <p className="nav-item-text">
                <Link href="/[lang]/wishlist" as={`/${selectedLanguage.key}/wishlist`}>
                  <a>{T.wishlist}</a>
                </Link>
              </p>
            </div>
          </li>
          <li className="hm-minicart">
            <div
              id="myCartContainer"
              className="text-white text-center icon-container"
              onClick={() =>
                this.setState({
                  cartDisplayed: !cartDisplayed,
                  accountDisplayed: false,
                })
              }
            >
              <i className="fa fa-shopping-cart" />
              <span className="item-count">{products.length}</span>
              <p className="nav-item-text">{T.my_cart}</p>
            </div>
            <div className="minicart" style={{ display: this.state.cartDisplayed ? 'block' : 'none' }} id="cartMini">
              <ul className="minicart-product-list">
                {products.map((prod) => {
                  const p = processProductDetails(prod, selectedLanguage.key)
                  return (
                    <li key={p.id}>
                      <Link as={`/${selectedLanguage.key}/products/${p.slug}`} href="/[lang]/products/[productID]">
                        <a d-href={`/${selectedLanguage.key}/products/${p.slug}`} className="minicart-product-image">
                          <LazyLoadImage src={getPrimaryImage(p.images)} alt="FB's Thumbnail" />
                          {/* <span className="product-quantity">{p.units}</span> */}
                        </a>
                      </Link>
                      <div className="minicart-product-details" style={{ maxWidth: 220 }}>
                        <h6>
                          <Link as={`/${selectedLanguage.key}/products/${p.slug}`} href="/[lang]/products/[productID]">
                            <a>{p.name}</a>
                          </Link>
                        </h6>
                        <div className="cart-plus-minus" onClick={(e) => e.stopPropagation()}>
                          <input
                            className="cart-plus-minus-box"
                            type="text"
                            name="quantity"
                            key={roundToTwo(p.units * p.amountInPackage)}
                            defaultValue={roundToTwo(p.units * p.amountInPackage)}
                            onBlur={(e) => this.onBlur(e, p.id)}
                            onClick={(e) => e.target.select()}
                            onChange={(e) => {
                              const val = e.target.value
                              if (isNaN(val) && val !== '.') {
                                return
                              }
                              const { quantities } = this.state
                              quantities[p.id] = val

                              this.setState({
                                quantities,
                              })
                            }}
                          />
                          <span className="cart-border-unit">{p.productMetricUnit.name}</span>
                          <div
                            onClick={() => {
                              this.incrementOrDecrement('dec', p.id)
                            }}
                            className="dec qtybutton"
                          >
                            -
                          </div>
                          <div
                            onClick={() => {
                              this.incrementOrDecrement('inc', p.id)
                            }}
                            className="inc qtybutton"
                          >
                            +
                          </div>
                        </div>
                        <span className="mt-0 float-right">
                          {`${getSumForProduct(p, selectedCurrency, p.units, vatType)}`}
                        </span>
                        <br />
                        {p.available ? (
                          <span className="product-availability float-right mt-0">{T.in_stock}</span>
                        ) : (
                          <span className="product-not-availability float-right mt-0">{T.out_stock}</span>
                        )}
                      </div>
                      <button onClick={() => this.removeProduct(p.id)} className="close" title="Remove" type="button">
                        <i className="fa fa-close" />
                      </button>
                    </li>
                  )
                })}
              </ul>
              {this.props.cartButtonLoading ? (
                <h6 className="text-center mt-4">
                  <Loader />
                </h6>
              ) : products.length > 0 ? (
                <div className="minicart-button text-center">
                  <div className="text-left mb-2 d-flex justify-content-between align-items-center">
                    <h3 style={{ textTransform: 'capitalize' }}>{T.products}</h3>
                    <span style={{ color: '#e23e57' }}>{sumPriceVAT(products, selectedCurrency)}</span>
                  </div>
                  <div className="text-left mb-2 d-flex justify-content-between align-items-center">
                    <h3 style={{ textTransform: 'capitalize' }}>{T.shipping}</h3>
                    <span style={{ color: '#e23e57' }}>
                      {currencyFormatter(shippingFee, selectedCurrency.identifier)}
                    </span>
                  </div>
                  <div className="text-left mb-2 d-flex justify-content-between align-items-center">
                    <h3 style={{ textTransform: 'capitalize' }}>{T.total}</h3>
                    <span style={{ color: '#e23e57' }}>{sumPriceVAT(products, selectedCurrency, shippingFee)}</span>
                  </div>
                  <Link href="/[lang]/cart" as={`/${selectedLanguage.key}/cart`}>
                    <a className="fb-btn w-100" onClick={() => this.setState({ cartDisplayed: false })}>
                      <span>{T.go_to_cart}</span>
                    </a>
                  </Link>
                </div>
              ) : (
                <h6 className="text-center mt-2">{T.empty_cart}</h6>
              )}{' '}
            </div>
          </li>
        </ul>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    cartButtonLoading: state.cart.buttonLoading,
    selectedLanguage: state.language.selectedLanguage,
    wishlist: state.wishlist.list,
    products: state.cart.products,
    selectedCurrency: state.currency.selectedCurrency,
    vatType: state.vat.type,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCartItems: (langCode) => dispatch(getAllCartItems(langCode)),
    removeFromCart: (id, lang) => dispatch(removeFromCart(id, lang)),
    doGetWishlist: (langCode) => dispatch(getWishList(langCode)),
    doUpdateCart: (products, lang) => dispatch(updateCart(products, lang)),
    doAddToCart: (product, lang) => dispatch(addToCart(product, lang)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)
