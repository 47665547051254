import React, { useCallback, useEffect, useRef, useState } from 'react'
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api'
import { toast } from 'react-toastify'
import Translation from '@translation'

// This example presents a way to handle editing a Polygon
// The objective is to get the new path on every editing event :
// - on dragging the whole Polygon
// - on moving one of the existing points (vertex)
// - on adding a new point by dragging an edge point (midway between two vertices)

// We achieve it by defining refs for the google maps API Polygon instances and listeners with `useRef`
// Then we bind those refs to the currents instances with the help of `onLoad`
// Then we get the new path value with the `onEdit` `useCallback` and pass it to `setPath`
// Finally we clean up the refs with `onUnmount`

function GoogleMapContainer ({ position, coordinates, selectedLanguage }) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBBjhFyEW2vSroueNsLRprIMEmXQlRtXjc',
  })

  // Store Polygon path in state
  const [path, setPath] = useState(coordinates)

  // Define refs for Polygon instance and listeners
  const polygonRef = useRef(null)
  const listenersRef = useRef([])

  useEffect(() => {
    if (position) {
      const coors = new google.maps.LatLng(position.lat, position.lng)
      const polygon = new google.maps.Polygon({ paths: path })
      const containLocation = google.maps.geometry.poly.containsLocation(coors, polygon)

      const T = Translation(selectedLanguage)

      if (containLocation) {
        toast.success(T.we_deliver_to_that_address)
      } else {
        toast.error(T.we_do_not_deliver_to_that_address)
      }
    }
  }, [position, polygonRef])


  // Call setPath with new edited path
  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() }
        })
      setPath(nextPath)
    }
  }, [setPath])

  // Bind refs to current Polygon and listeners
  const onLoad = useCallback(
    polygon => {
      polygonRef.current = polygon

      const p = polygon.getPath()

      listenersRef.current.push(
        p.addListener('set_at', onEdit),
        p.addListener('insert_at', onEdit),
        p.addListener('remove_at', onEdit),
      )
    },
    [onEdit],
  )

  // Clean up refs
  const onUnmount = useCallback(() => {
    listenersRef.current.forEach(lis => lis.remove())
    polygonRef.current = null
  }, [])

  return (
    <div className="App">
      <div
        id="script-loader"
      >
        <GoogleMap
          mapContainerClassName="App-map"
          center={ { lat: 50.0842944, lng: 14.4137333 } }
          zoom={ 11 }
          version="weekly"
          on
        >
          <Polygon
            path={ path }
            onLoad={ onLoad }
            onUnmount={ onUnmount }
          />
        </GoogleMap>
      </div>
    </div>
  )
}

export default GoogleMapContainer
