import * as languageActions from '../actions/language'

const initialState = {
  selectedLanguage: { key: 'en', name: 'English' },
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case languageActions.SET_LANGUAGE:
      return {
        ...state,
        selectedLanguage: action.selectedLanguage,
      }
    default:
      return state
  }
}

export default reducer
