import * as modalActions from '../actions/modal'

const initialState = {
  status: '',
  product: {},
}

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case modalActions.OPEN_MODAL:
      return {
        ...state,
        status: 'show',
        product: action.product,
      }

    case modalActions.CLOSE_MODAL:
      return {
        ...state,
        status: ' ',
        product: {},
      }

    default:
      return state
  }
}

export default modalReducer
