/* eslint-disable global-require */
import React, { useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import Header from '@components/ui/header'
import Footer from '@components/ui/footer'
import { withReduxStore } from '@store/index'
import { logPageView } from '@helpers/analytics'
import ProductModal from '@components/ui/productModal'
import { useDispatch, useSelector } from 'react-redux'
import { closeModal } from '@store/actions/modal'
import Head from 'next/head'
import { toast, ToastContainer } from 'react-toastify'
import getConfig from 'next/config'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import Translation from '@translation'
import qs from 'query-string'
// app styles
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import 'react-dropdown/style.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'lightgallery.js/dist/css/lightgallery.css'
import 'react-datepicker/dist/react-datepicker.css'
// import 'react-pdf/dist/Page/AnnotationLayer.css'
import '../styles/helper.css'
import '../styles/style.css'
import '../styles/app.css'
import parseCookie from 'helpers/cookies'
import { USER_SESSION_ID } from '@store/actions/login'
import UserService from '@services/userService'
import { initSentry } from 'helpers/sentry'
import { setToken } from '@services/'

const { publicRuntimeConfig } = getConfig()

const stripePromise = loadStripe(publicRuntimeConfig.STRIPE_PUBLISHABLE_KEY)

// WARN do not change require order od this one
if (typeof window !== 'undefined') {
  require('jquery')
  require('popper.js')
  require('bootstrap')
  require('../public/assets/js/plugins')
  require('../public/assets/js/main')
}

initSentry()

// running process bar
NProgress.configure({ showSpinner: false })
Router.onRouteChangeStart = () => {
  localStorage.setItem('__PRE_LOCATION__', `${window.location.pathname}${window.location.search}`)
  const $ = window.jQuery
  if ($('.pdetails-largeimages')) {
    $('.pdetails-thumbs:not(.pdetails-thumbs-vertical)').slick('unslick')
    $('.pdetails-thumbs-vertical').slick('unslick')
    $('.pdetails-largeimages').slick('unslick')
  }

  if (window.__MyLightGallery__) {
    window.__MyLightGallery__.data('lightGallery')?.destroy(true)
  }

  NProgress.start()
}
Router.onRouteChangeComplete = () => {
  logPageView()
  NProgress.done()
}
Router.onRouteChangeError = () => NProgress.done()

function App({ Component, pageProps }) {
  const dispatch = useDispatch()
  const product = useSelector((state) => state.modal.product)
  const selectedLanguage = useSelector((state) => state.language.selectedLanguage)
  const token = useSelector((state) => state.login?.user?.token)

  const { pathname, asPath } = useRouter()

  useEffect(() => {
    const { sessionId } = parseCookie()
    if (!sessionId || sessionId === 'undefined' || sessionId === 'null') {
      UserService.getSessionId().then(({ sessionId: id }) => {
        dispatch({ type: USER_SESSION_ID, sessionId: id })
        localStorage.setItem('sessionId', id)
        document.cookie = `sessionId=${id};path=/`
      })
    } else {
      localStorage.setItem('sessionId', sessionId)
      dispatch({ type: USER_SESSION_ID, sessionId })
    }

    // set hamburger button
    setTimeout(() => {
      if (typeof window.jQuery === 'function') {
        window.jQuery('.fb-navigation').meanmenu({
          meanScreenWidth: '991',
        })
      }
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])

  useEffect(() => {
    const { welcomeback, message } = qs.parse(asPath.split('?')[1])
    if (welcomeback) {
      const T = Translation(selectedLanguage)
      toast.success(`${T.welcome} BatiBau`)
    }

    if (message) {
      toast.success(message)
    }

    if (typeof window !== 'undefined' && (message || welcomeback)) {
      window.history.replaceState(null, window.document.title, window.location.pathname)
    }
  }, [asPath, selectedLanguage])

  useEffect(() => {
    setToken(token)
  }, [token])

  return (
    <div className="body-wrapper">
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Head>
      <Header pathname={pathname} langCode={selectedLanguage.key} />
      <div className="content-wrapper">
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ elements, stripe }) => <Component {...pageProps} elements={elements} stripe={stripe} />}
          </ElementsConsumer>
        </Elements>

        {product?.id && <ProductModal product={product} onClose={() => dispatch(closeModal(null))} />}
      </div>
      <Footer />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}

export default withReduxStore(App, { ssr: true })
