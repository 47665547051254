import * as currencyActions from '../actions/currency'

const initialState = {
  selectedCurrency: {
    name: 'Euro',
    identifier: 'EUR',
    symbol: '€',
    exchangeRateToCzk: 1,
  },
  currencies: [
    {
      id: 1,
      name: 'Czech Republic Koruna',
      identifier: 'CZK',
      symbol: 'Kč',
      active: false,
      exchangeRateToCzk: 1,
      position: 1,
      createdAt: '2020-01-18T13:08:22.138Z',
    },
    {
      id: 2,
      name: 'Euro',
      identifier: 'EUR',
      symbol: '€',
      active: false,
      exchangeRateToCzk: 0.03636798,
      position: 1,
      createdAt: '2020-01-18T13:08:25.170Z',
    },
  ],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case currencyActions.SET_CURRENCY:
      return {
        ...state,
        selectedCurrency: action.selectedCurrency,
      }
    case currencyActions.SET_CURRENCIES:
      return {
        ...state,
        currencies: action.currencies,
        selectedCurrency:
          action.currencies.find((cc) => cc.identifier === state.selectedCurrency.identifier) || state.selectedCurrency,
      }

    default:
      return state
  }
}

export default reducer
