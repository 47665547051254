import * as cartActions from '../actions/cart'

const initialState = {
  buttonLoading: false,
  loading: false,
  products: undefined,
  errorMessage: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case cartActions.ADD_STARTED:
      return {
        ...state,
        buttonLoading: true,
      }
    case cartActions.ADD_SUCCESS:
      return {
        ...state,
        buttonLoading: false,
        products: action.products.sort((a, b) => a.id - b.id),
      }
    case cartActions.ADD_FAILED:
      return {
        ...state,
        buttonLoading: false,
        errorMessage: action.errorMessage,
      }
    case cartActions.CART_ITEMS_STARTED:
      return {
        ...state,
        loading: true,
        errorMessage: action.errorMessage,
      }
    case cartActions.CART_ITEMS_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      }
    case cartActions.CART_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.products.sort((a, b) => a.id - b.id),
      }
    default:
      return state
  }
}

export default reducer
