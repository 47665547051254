module.exports = {
  home: 'Home',
  cart: 'Cart',
  error: 'Error',
  there: 'There',
  are: 'are',
  empty_wishlist_message: 'Your wishlist is empty',
  page_translated_successfully: 'Page translated successfully!',
  name: 'Name',
  we_deliver_to_that_address: 'We deliver to that address!',
  we_do_not_deliver_to_that_address: 'We do not deliver to that address',
  password_requested_successfully: 'New password requested successfully',
  forgotten_password: 'Forgotten password ?',
  proceed_to_checkout: 'Proceed to checkout',
  unit_price: 'Unit Price',
  units: 'Units',
  amount_in_package: 'Amount in package',
  password_incorrect: 'Password incorrect',
  packages: 'packages',
  pcs: 'pcs',
  cart_totals: 'Cart Totals',
  price: 'Price',
  rating: 'Rating',
  maximum_pieces_exceeded: 'Maximum pieces exceeded',
  unsubscribe_success: 'Successfully unsubscribed from newsletter',
  model: 'Model',
  feedback_sent_successfully: 'Thanks for your feedback',
  products: 'products',
  search: 'Search',
  page_could_not_be_found: 'PAGE COULD NOT BE FOUND',
  back_to_home_page: 'Back to home page',
  page_temporarily_unavailable: 'Sorry but the page you are looking for does not exist',
  all_selected_items: 'All selected items',
  my_cart: 'My Cart',
  working_days_hours: 'Working Days/Hours',
  free_support_line: 'Free Support Line',
  orders_support: 'Orders Support',
  monday_to_friday: 'Mon - Fri',
  remove: 'remove',
  removing: 'Removing...',
  images: 'images',
  image: 'Image',
  contact: 'Contact',
  top_sellers: 'Top Sellers',
  wall: 'Wall',
  floor: 'Floor',
  electrical: 'Electrical',
  todays_deals: "Today's deals",
  on_sale: 'on sale',
  about_us: 'About Us',
  new_products: 'New Products',
  popular_products: 'Popular Products',
  contact_us: 'Contact Us',
  contact_message: 'For any questions, please contact us using the form on the left.',
  tell_us_your_message: 'Tell us your message',
  what_are_you_looking_for: 'Search',
  your_name: 'Your Name',
  your_email: 'Your Email',
  subject: 'Subject',
  your_message: 'Your Message',
  phone: 'Phone',
  email: 'Email',
  enter_new_password: 'Enter new password',
  confirm_new_password: 'Confirm new password',
  password_reset_successfully: 'Password reset successfully. You can log in now.',
  password_missing: 'Password missing',
  confirm_password_missing: 'Confirm password missing',
  something_is_wrong_with_the_link: 'Something is wrong with the link. Make sure you cliked on the link from the email',
  verifying_link: 'Verifying Link',
  reset_password: 'Reset Password',
  loading: 'Loading...',
  request_new_password: 'Request new password',
  email_address: 'Email Address',
  email_exists: 'Customer with that email already exists.',
  mobile: 'Mobile',
  hotline: 'Hotline',
  address: 'Address',
  signed_out_successfully: 'Signed out successfully',
  contact_us_message: 'Contact us etiam processus dynamicus',
  welcome: 'Welcome to',
  world: 'world',
  empty_orders_table_message: 'Once you make a purchase your orders will appear here',
  email_verification_success: 'Email verified successfully.',
  email_verification_failed: 'Email verification failed',
  verifying_email: 'Verifying email...',
  missing_billing_address: 'Missing billing address',
  customer_already_exists: 'Customer with that email already exists. Try logging in instead',
  customer_does_not_exist: 'Customer with that email does not exist.',
  up_to_50_off: 'Up to 50% off',
  button_submitting: 'Submitting...',
  shop_now: 'Shop Now',
  be_the_hour_glass: 'Be the Hour Glass',
  confirm_email_page: 'Confirm email',
  our_team_members: 'Our Team Members',
  our_skills: 'Our skills',
  enter_valid_email: 'Please enter valid email address',
  subscription_successful: 'Subscription successful. Please confirm your email.',
  sign_up_for_newsletters: 'Sign up for newsletters',
  be_the_first_to_know: 'Be The First To Know. Sign Up For Newsletter Today',
  your_account: 'Your Account',
  contact_info: 'Contact Info',
  orders: 'Orders',
  profile_and_orders: 'Profile and orders',
  profile: 'Profile',
  enter_your_email: 'Enter your email',
  subscribe: 'Subscribe',
  showing: 'Showing',
  of: 'of',
  send: 'send',
  items: 'item(s)',
  previous: 'Previous',
  next: 'Next',
  sort_by: 'Sort By',
  total: 'Total',
  relevance: 'Relevance',
  our_company: 'Our Company',
  all_categories: 'All Categories',
  congratulations: 'Congratulations!',
  terms_and_conditions: 'Terms and conditions',
  privacy_policy: 'Privacy policy',
  order_created: 'Your order has been successfully created!',
  order_success_message: 'Soon',
  will_recieve_email_soon: 'Soon',
  all_rights_reserved: 'All rights reserved.',
  anonymous: 'Anonymous',
  new: 'New',
  go_to_cart: 'Go to cart',
  // input messages(errors and success)
  required_input: 'All fields are required!',
  invalid_email: 'Please enter a valid email!',
  first_name_missing: 'First name field missing!',
  last_name_missing: 'Last name field missing!',
  register_password_missing: 'Password and confirm password field are required!',
  password_match: 'Password and confirm password field do not match!',
  login_password_missing: 'Password field is missing!',
  address_missing: 'Address field missing!',
  invalid_card_details: 'Invalid card details',
  phone_missing: 'Phone field missing!',
  phone_number_exists: 'Customer with that phone number already exists',
  company_missing: 'Company name field missing!',
  ico_missing: 'ICO field missing!',
  dic_missing: 'DIC field missing!',
  email_missing: 'Email field missing!',
  // Login/Register form (labels and placeholders)
  login_register: 'Sign In | Register',
  login: 'Login',
  password: 'Password',
  remember_me: 'Remember me',
  register: 'Register',
  first_name: 'First Name',
  last_name: 'Last Name',
  street_address: 'Street Address',
  company_name: 'Company Name',
  ico: 'ICO',
  dic: 'DIC',
  language: 'Language',
  currency: 'Currency',
  checkout: 'Checkout',
  wishlist: 'Wishlist',
  account: 'Account',
  sign_out: 'Sign out',
  sign_in: 'Sign in',
  country_code: 'Country code',
  confirm_password: 'Confirm password',
  // User profile page translation
  user_profile: 'User Profile',
  user_password: 'User Password',
  current_password: 'Current Password',
  new_password: 'New Password',
  submit: 'Submit',
  order_id: 'Order',
  created: 'Created',
  status: 'Status',
  invoice: 'Invoice',
  download: 'Download',
  access_profile: 'Log in or register to access your profile !',
  // Checkout page translation
  billing_details: 'Billing Details',
  ship_to_address: 'Ship to different address ?',
  billing_address: 'Billing Address',
  shipping_address: 'Shipping Address',
  street: 'Street',
  street_number: 'Street number',
  sublocality: 'Sublocality',
  country: 'Country',
  order_notes: 'Order Notes',
  note_description: 'Notes about your order.',
  your_order: 'Your order',
  product: 'Product',
  net_price: 'Net price',
  order_total: 'Order Total',
  direct_bank: 'Direct Bank Transfer',
  bank: 'Bank',
  card_information: 'Card information',
  card_number: 'Card Number',
  card_type: 'Card type',
  date: 'Date',
  cvv: 'CVV',
  zip: 'Zip',
  cheque_payment: 'Cheque Payment',
  cheque_payment_description:
    'Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.',
  paypal_description:
    'Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order won’t be shipped until the funds have cleared in our account.',
  access_checkout: 'Log in or register to access your checkout !',
  wishlist_item_already_in_list: 'Item already in list',
  creating_order: 'Creating order...',
  place_order: 'Place order',
  missing_billing_details: 'Missing fields in billing details',
  missing_shipping_address: 'Missing fields in shipping address',
  cart_empty: 'Cart is empty. Please add products to cart to continue!',
  cannot_create_customer: 'Could not create customer',
  cannot_create_guest_customer: 'Could not create guest customer',
  cannot_create_order: 'Could not create order',
  cannot_create_transaction: 'Could not create transaction in Stripe',
  cannot_update_transaction: 'Could not update order with transaction id from Braintree',
  done: 'Done!',
  thank_you_for_your_purchase: 'Thank you for your purchase!',
  new_order: 'New order created successfully',
  create_account: 'Create an account?',
  create_account_message:
    'Create an account by entering the information below. If you are a returning customer please login at the top of the page.',
  account_password: 'Account password',
  bad_address: 'Something is wrong with that address. Make sure you have street number',
  enter_card_num: 'Enter your card number',
  enter_card_date: 'Expiration date (MM/YYYY)',
  something_went_wrong: 'Something went wrong, please try again',
  email_already_exists: 'Email already exists',
  enter_card_cvv: 'Enter card CVV',
  enter_zip: 'Enter zip',
  // product details page
  changed_product_quantity: 'Successfully changed product quantity !',
  product_in_cart: 'This product is already in cart !',
  add_product: 'Successfully added product in cart !',
  adding: 'Adding...',
  add_to_cart: 'Add to cart',
  and: 'and',
  out_of_stock: 'Out of stock',
  delivery_policy: 'Delivery Policy',
  security_policy: 'Security Policy',
  return_policy: 'Return Policy',
  delivery_policy_text: 'Delivery Policy Text',
  security_policy_text: 'Security Policy Text',
  return_policy_text: 'Return Policy Text',
  description: 'Description',
  added_to_wishlist: 'Added to wishlist',
  product_details: 'Product Details',
  product_number: 'Product code',
  write_review: 'Write Review',
  in_stock: 'In stock',
  out_stock: 'Out of stock',
  share: 'Share',
  reviews: 'Reviews',
  customer: 'Customer',
  review_desc: 'Review description',
  add_review: 'Add a review',
  first_review: 'Be the first one to add a review',
  our_feedback: 'Our Feedback',
  your_rating: 'Your rating',
  your_review: 'Your Review',
  login_to_add_review: 'You must be logged in to post a review',
  close: 'Close',
  product_code: 'Product code',
  // empty cart page
  empty_cart: 'Your Shopping Cart is empty',
  great_deals: "Don't miss out on great deals !",
  shopping_now: 'Start Shopping Now !',

  // search(products) page
  there_are: 'There are',
  name_asc: 'Name (A - Z)',
  name_desc: 'Name (Z - A)',
  price_asc: 'Price (Low to High)',
  price_desc: 'Price (High to Low)',
  similar_products: 'Similar Products',
  brands: 'Brands',
  new_in_shop: 'New in shop',
  last_7_days: 'Last 7 days',
  last_30_days: 'Last 30 days',
  last_6_months: 'Last 6 months',

  // empty products page
  empty_products: 'No results for your search',
  empty_product_desc: 'Try checking your spelling or use more general terms',
  no_results: 'No results',
  need_help: 'Need help?',

  // footer
  follow_us: 'Follow Us',
  policies: 'Policies',
  all_products: 'All Products',

  // cart
  success_delete: 'Successfully deleted product',

  // Submit your feedback
  submit_your_feedback: 'Submit your feedback',
  help_improve: 'HELP US IMPROVE',
  shopping_experience: 'HOW WAS YOUR SHOPPING EXPERIENCE?',
  comments: 'COMMENTS',
  comments_placeholder: 'Please enter your comments here',

  // unsubscribe
  email_unsubscribe: 'Email unsubscribe',
  unsubscribe_text: 'Are you sure you want to unsubscribe from Batibau newsletter?',
  unsubscribe: 'Unsubscribe',

  // orders
  your_orders: 'Your Orders',
  order_details: 'Order detail',

  // contact page
  submited_success_message: 'Your message has been submitted successfully!',
  missing_fields: 'Missing fields',
  // profile
  vat_rate: 'VAT rate',
  update_profile: 'User profile updated',
  password_less_char: 'New password cannot be less than 5 characters',
  current_password_missing: 'Current password missing',
  new_password_missing: 'New password missing',
  confirm_new_password_missing: 'Confirm new password missing',
  password_updated: 'User password updated',
  incorrect_password: 'Current password is incorrect',
  password_length_error: 'Password cannot have fewer than 6 characters',
  // footer
  copyright: 'Copyright',

  // about us page
  about_us_header: 'About Us',
  about_us_first_paragraph:
    'Our vision is to put efficiency and transparency in the construction for privates and professionals.',
  about_us_second_paragraph:
    'With Batibau, you can buy building materials. Our team is here to guarantee your delivery within 24 hours. Our products and services are available for businesses and private clients. It is always necessary to log in or re-register the buyer. We believe that the combination of our services, quality of goods and competitive prices will succeed to your full satisfaction.',
  about_us_third_paragraph: 'We wish you a pleasant shopping experience.',
  about_us_team: 'Team Batibau',
  about_business_info: 'Business informations:',
  max_quantity_exceeded: 'You only can add maximum',
  quantity: 'Quantity',
  total_price: 'Total',
  total_cart_price: 'Total',
  vat: 'VAT',
  checking_inventory: 'Checking inventory',
  purchase_to_review: 'Purchase to review',
  missing_street_number: 'Missing street number',
  invalid_address: 'Invalid adress',
  modify_order: 'Modify quantity',

  clear: 'Clear',
  no_products_in_category: 'No products fit your criteria',
  remove_out_of_stock_message: 'Please remove out of stock item to continue check out',
  vat_included: 'VAT Included',
  vat_excluded: 'VAT Excluded',
  in: 'IN',
  fast_delivery: 'Fast delivery',
  no_details: 'No more details',
  downloads: 'Downloads',
  previous_page: 'Previous page',
  next_page: 'Next Page',
  unavailable: 'Unavailable',
  variants: 'Variants',
  expected_delivery_date: 'Expected delivery date',
  payment_method: 'Payment Methods',
  success_delete_payment: 'Successfully deleted payment method',
  add_new: 'Add new',
  continue_with_existing: 'Continue with existing card',
  your_credit_cards: 'Your Credit Cards',
  expires: 'Expires',
  card_name: 'Name on card',
  empty_cards_text: 'You do not have any credit cards stored',
  delivery_date: 'Delivery date',
  select_desired_date: 'Select desired date',
  select_time_range: 'Select time range',
  shippingFee: 'Shipping fee',
  shipping: 'Shipping',
  transport: 'Doprava',
  net_shipping_fee: 'Net shipping fee',
  select_delivery_date: 'Please select delivery date',
  notify_me: 'Notify me',
  incomplete_card_details: 'Incomplete card details.',
  agreement: 'I agree with the Batibau General Terms and Conditions.',
  agreement_error: 'Please accept general terms and conditions.',
  total_products: 'Total products',
  total_shipping: 'Total shipping',
  price_total: 'Total price',
  included_vat: 'VAT included',
  minimum_order_amount_czk: 'Minimum order is CZK 500',
  minimum_order_amount_eur: 'Minimum order is EUR 20',
  for_you: 'for you',
}
