import userService from '@services/userService'

export const LOGIN_STARTED = 'LOGIN_STARTED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SIGN_OUT = 'SIGN_OUT'
export const GET_USER = 'GET_USER'

export const REGISTER_STARTED = 'REGISTER_STARTED'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_ERROR = 'REGISTER_ERROR'

export const USER_SESSION_ID = 'USER_SESSION_ID'

export const registerStarted = () => {
  return {
    type: REGISTER_STARTED,
  }
}

export const registerSuccess = ({ user }) => {
  return {
    type: REGISTER_SUCCESS,
    user,
  }
}

export const registerError = ({ errorMessage }) => {
  return {
    type: REGISTER_ERROR,
    errorMessage,
  }
}

export const loginStarted = () => {
  return {
    type: LOGIN_STARTED,
  }
}

export const loginSuccess = ({ user }) => {
  return {
    type: LOGIN_SUCCESS,
    user,
  }
}

export const loginError = (payload) => {
  return {
    type: LOGIN_ERROR,
    errorMessage: payload.errorMessage,
  }
}

export const signOut = ({ user }) => {
  return {
    type: SIGN_OUT,
    user,
  }
}

export const onLogin = (data, rememberMe = false) => {
  return async (dispatch) => {
    try {
      dispatch(loginStarted())
      const user = await userService.login(data)
      userService.setToken(user, rememberMe)
      dispatch(loginSuccess({ user }))
    } catch (err) {
      dispatch(loginError({ errorMessage: err.message || 'something went wrong' }))
      throw err
    }
  }
}

export const onRegister = (data, lang) => {
  return async (dispatch) => {
    dispatch(registerStarted())
    try {
      const user = await userService.registerCustomer(data, lang)
      userService.setToken(user, true)
      dispatch(loginSuccess({ user }))
      dispatch(registerSuccess({ user }))
    } catch (err) {
      dispatch(registerError({ errorMessage: err.message || 'Something went wrong' }))

      throw err
    }
  }
}

export const onSignOut = () => {
  return async (dispatch) => {
    // make sure to remove old method
    localStorage.removeItem('ibuilt_user')
    await userService.removeToken()
    dispatch(signOut({ user: null }))
  }
}
