import React, { Component } from 'react'

import css from './loader.module.css'

class Loader extends Component {
  render() {
    return (
      <div className={css.loader}>
        <i className="fa fa-spinner fa-spin" />
      </div>
    )
  }
}

export default Loader
