import coreAPI from '.'

const SubscriptionService = {
  subcribe(email, lang) {
    return coreAPI.put(`/api/email-subscriptions?lang=${lang}`, { email })
  },

  unsubscribe(email) {
    return coreAPI.get(`/api/email-subscriptions/unsubscribe?email=${email}`)
  },

  verifyEmail(uniqueId, lang) {
    return coreAPI.get(`/api/email-subscriptions/verify-email?uniqueId=${uniqueId}&lang=${lang}`)
  },

  subscribeAProduct(email, productId, lang) {
    return coreAPI.post(`/api/email-subscriptions/products?lang=${lang}`, { email, productId })
  },
}

export default SubscriptionService
