import axios from 'axios'

import helperFuncs from '../helpers/helperFuncs'

const baseURL = helperFuncs.getBaseUrl(process.env.NODE_ENV)

const coreAPI = axios.create({
  baseURL,
  timeout: 30000, // 30s,
  // withCredentials: true,
})

coreAPI.interceptors.request.use(
  (config) => {
    if (config.method === 'get') {
      console.log(`${config.method} - ${config.url}`)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

coreAPI.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (error.response) {
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error)
  },
)

export function setToken(token) {
  coreAPI.defaults.headers.common.Authorization = `Bearer ${token}`
}

export function removeToken() {
  delete coreAPI.defaults.headers.common.Authorization
}

export default coreAPI
