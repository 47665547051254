import ProductService from '@services/productService'

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export const closeModal = (product) => {
  // make sure remove modal backdrop
  $('.modal-backdrop').remove()
  $('body').removeClass('modal-open')
  return {
    type: CLOSE_MODAL,
    product,
  }
}

export const openModal = (product, lang, token) => async (dispatch) => {
  if (!product?.id) {
    return dispatch({ type: CLOSE_MODAL })
  }
  try {
    const productDetailPromise = ProductService.getOne(product.id, lang, token)
    dispatch({
      type: OPEN_MODAL,
      product,
    })
    const p = await productDetailPromise
    dispatch({
      type: OPEN_MODAL,
      product: p,
    })
  } catch (err) {
    console.error(err)
    return closeModal()
  }
}
