import React, { Component } from 'react'
import Link from 'next/link'
import { connect } from 'react-redux'
import loMap from 'lodash/map'

import Translation from '@translation'
import { getAllProductCollections } from '@store/actions/productCollectionActions'
import { processMenuItemDetails } from 'helpers/helperFuncs'
import ProductService from '@services/productService'
import { setCurrencyList } from '@store/actions/currency'
import { LazyLoadImage } from 'react-lazy-load-image-component'

class Navigation extends Component {
  constructor(props) {
    super(props)
    this.toggleCategories = null
    this.categoriesExpanded = false
    this.state = { sticky: false }
  }

  async componentDidMount() {
    try {
      const { doSetCurrencyList } = this.props
      const { currencies } = await ProductService.getCurrencies()
      doSetCurrencyList(currencies)
    } catch (err) {
      console.error('err', err)
    }

    const $ = window.jQuery
    setTimeout(
      () => {
        $(window).on('scroll', () => {
          const { sticky } = this.state
          if ($(window).scrollTop() > 300) {
            $('.header-sticky').addClass('sticky')
            if (!sticky) {
              this.setState({ sticky: true })
              window.jQuery('.fb-navigation').meanmenu({
                meanScreenWidth: '991',
              })
            }
          } else {
            $('.header-sticky').removeClass('sticky')
            if (sticky) {
              this.setState({ sticky: false })
            }
          }
        })
      },
      typeof $ === 'function' ? 0 : 1000,
    )
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target) && this.categoriesExpanded) {
      this.toggleCategories()
      this.categoriesExpanded = false
    }
  }

  handleClickCollection = (id) => {
    const { getSingleCollection } = this.props

    return getSingleCollection(id)
  }

  mapNavigationItems = () => {
    const { user, menuItems, selectedLanguage, wishlist, cartProducts } = this.props
    const T = Translation(selectedLanguage)
    const { sticky } = this.state
    return (
      <ul>
        {loMap(menuItems, processMenuItemDetails(selectedLanguage.key)).map((m, idx) => (
          <li key={m.id || idx}>
            <Link href="/[lang]/[menuSlug]" as={`/${selectedLanguage.key}/${m.slug}`}>
              <a>{m.name}</a>
            </Link>
          </li>
        ))}

        <li className="delivery" style={{ display: !sticky ? 'flex' : 'none' }}>
          <LazyLoadImage
            src="/assets/images/truck.png" alt="truck icon"
          />
          <p className="m-0">
            <span className="d-block text-red">{T.in} 24h</span>
            <span className="d-block text-white">{T.fast_delivery}</span>
          </p>
        </li>

        {user && (
          <li style={{ display: sticky ? 'inline-block' : 'none' }}>
            <Link href="/[lang]/profile" as={`/${selectedLanguage.key}/profile`}>
              <a className="nav-icon-container">
                <i className="fa fa-user no-counting" />
                <span>{T.profile}</span>
              </a>
            </Link>
          </li>
        )}
        <li style={{ display: sticky ? 'inline-block' : 'none' }}>
          <Link href="/[lang]/wishlist" as={`${selectedLanguage.key}/wishlist`}>
            <a className="nav-icon-container">
              <i className="fa fa-heart" />
              <span>{T.wishlist}</span>
              <span className="item-count">{wishlist?.length || 0}</span>
            </a>
          </Link>
        </li>
        <li style={{ display: sticky ? 'inline-block' : 'none' }}>
          <Link href="/[lang]/cart" as={`${selectedLanguage.key}/cart`}>
            <a className="nav-icon-container">
              <i className="fa fa-shopping-cart" />
              <span>{T.my_cart}</span>
              <span className="item-count">{cartProducts?.length || 0}</span>
            </a>
          </Link>
        </li>
        {!user && (
          <li style={{ display: sticky ? 'inline-block' : 'none' }}>
            <Link
              href="/[lang]/login"
              as={{
                pathname: `/${selectedLanguage.key}/login`,
                query: {
                  redirectBack: true,
                },
              }}
            >
              <a className="nav-icon-container">
                <i className="fa fa-sign-in no-counting" />
                <span>
                  {T.sign_in} | {T.register}
                </span>
              </a>
            </Link>
          </li>
        )}
      </ul>
    )
  }

  render() {
    return (
      <div className="header-bottom bg-polo-blue header-sticky stick">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 d-none d-lg-block d-xl-block position-static">
              <div className="fb-navigation" style={{ display: 'block' }}>
                {this.mapNavigationItems()}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="mobile-menu-area d-lg-none d-xl-none col-12">
              <div className="mobile-menu"></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.login.user,
    wishlist: state.wishlist.list,
    cartProducts: state.cart.products,
    selectedLanguage: state.language.selectedLanguage,
    selectedCurrency: state.currency.selectedCurrency,
    menuItems: state.categoryReducer.menuItems,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    retrieveProducts: () => dispatch(getAllProductCollections()),
    doSetCurrencyList: (list) => dispatch(setCurrencyList(list)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
