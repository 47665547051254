import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import SubscriptionService from '@services/subscriptionService'
import Link from 'next/link'
import Translation from '@translation'
import { toast } from 'react-toastify'
import { captureException } from 'helpers/sentry'

class Footer extends PureComponent {
  constructor(props) {
    super(props)
    this.handleSubscribe = this.handleSubscribe.bind(this)
    this.state = {
      subscribingEmail: '',
      submitting: false,
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  async handleSubscribe(event) {
    event.preventDefault()

    this.setState({ submitting: true })
    const lang = this.props.selectedLanguage

    const T = Translation(lang)

    if (!new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(this.state.subscribingEmail)) {
      return toast.warning(T.enter_valid_email)
    }

    try {
      await SubscriptionService.subcribe(this.state.subscribingEmail, lang.key)

      toast.success(T.subscription_successful)

      this.setState({ subscribingEmail: '' })
    } catch (err) {
      if (err.type === 'EMAIL_EXISTS') {
        toast.error(T.email_already_exists)
      } else {
        toast.error(T.something_went_wrong)
        captureException(err)
      }
    }

    this.setState({ submitting: false })
  }

  render() {
    const { selectedLanguage } = this.props
    const T = Translation(selectedLanguage)

    return (
      <div>
        <div className="fb-footer">
          <div className="fb-footer_top">
            <div className="container">
              <div className="row">
                <div className="col-lg-5">
                  <div className="fb-newsletters">
                    <h2 className="newsletters-headeing">{T.sign_up_for_newsletters}</h2>
                    <p className="short-desc">{T.be_the_first_to_know}</p>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="fb-newsletters_form pt-sm-15 pt-xs-15">
                    <form
                      onSubmit={this.handleSubscribe}
                      method="post"
                      id="mc-embedded-subscribe-form"
                      name="mc-embedded-subscribe-form"
                      className="footer-subscribe-form validate"
                      target="_blank"
                      noValidate
                    >
                      <div id="mc_embed_signup_scroll">
                        <div id="mc-form" className="mc-form subscribe-form form-group">
                          <input
                            id="mc-email"
                            type="email"
                            autoComplete="off"
                            placeholder={T.enter_your_email}
                            name="subscribingEmail"
                            value={this.state.subscribingEmail}
                            onChange={this.onChange}
                            disabled={this.state.submitting}
                          />
                          <button type="submit" className="btn mt-sm-15 mt-xs-15" id="mc-submit">
                            {this.state.submitting ? T.button_submitting : T.subscribe}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="fb-footer_middle bg-white">
            <div className="container">
              <div className="footer-middle_top">
                <div className="row">
                  <div className="col-xl-2 col-lg-2 col-md-6 col-sm-5 col-6">
                    <div className="fb-footer_widget pt-10">
                      <h3 className="fb-footer-widget-headeing">{T.your_account}</h3>
                      {this.props.user ? (
                        <ul>
                          <li>
                            <Link href="/[lang]/profile" as={`/${selectedLanguage.key}/profile`}>
                              <a>{T.profile}</a>
                            </Link>
                          </li>
                          <li>
                            <Link href="/[lang]/orders" as={`/${selectedLanguage.key}/orders`}>
                              <a>{T.orders}</a>
                            </Link>
                          </li>
                        </ul>
                      ) : (
                        <ul>
                          <li>
                            <Link
                              href="/[lang]/login"
                              as={{
                                pathname: `/${selectedLanguage.key}/login`,
                                query: {
                                  redirectBack: true,
                                },
                              }}
                            >
                              <a>
                                {T.sign_in} | {T.register}
                              </a>
                            </Link>
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-6 col-sm-5 col-6">
                    <div className="fb-footer_widget pt-10">
                      <h3 className="fb-footer-widget-headeing">{T.products}</h3>
                      <ul>
                        <li>
                          <Link href="/[lang]/top-sellers" as={`/${selectedLanguage.key}/top-sellers`}>
                            <a>{T.top_sellers}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href="/[lang]/todays-deals" as={`/${selectedLanguage.key}/todays-deals`}>
                            <a>{T.todays_deals}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href="/[lang]/new-products" as={`/${selectedLanguage.key}/new-products`}>
                            <a>{T.new_products}</a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-5 col-6">
                    <div className="fb-footer_widget pt-10 pt-xs-0">
                      <h3 className="fb-footer-widget-headeing">{T.our_company}</h3>
                      <ul>
                        <li>
                          <Link href="/[lang]/terms-and-conditions" as={`/${selectedLanguage.key}/terms-and-conditions`}>
                            <a>{T.terms_and_conditions}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href="/[lang]/privacy-policy" as={`/${selectedLanguage.key}/privacy-policy`}>
                            <a>{T.privacy_policy}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href="/[lang]/return-policy" as={`/${selectedLanguage.key}/return-policy`}>
                            <a>{T.return_policy}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href="/[lang]/about-us" as={`/${selectedLanguage.key}/about-us`}>
                            <a>{T.about_us}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href="/[lang]/contact" as={`/${selectedLanguage.key}/contact`}>
                            <a>{T.contact_us}</a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-6 col-sm-5 col-6">
                    <div className="fb-footer_widget-custom pt-10 pt-xs-0">
                      <h3 className="fb-footer-widget-headeing">{T.follow_us}</h3>
                      <div className="footer-widget-social-link">
                        <ul className="social-link social-footer">
                          <li className="facebook">
                            <a
                              href="https://www.facebook.com"
                              data-toggle="tooltip"
                              rel="noreferrer"
                              target="_blank"
                              title="Facebook"
                            >
                              <i className="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li className="twitter">
                            <a
                              href="https://twitter.com"
                              data-toggle="tooltip"
                              rel="noreferrer"
                              target="_blank"
                              title="Twitter"
                            >
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li className="instagram">
                            <a
                              href="https://www.instagram.com"
                              data-toggle="tooltip"
                              rel="noreferrer"
                              target="_blank"
                              title="Instagram"
                            >
                              <i className="fa fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-middle-bottom">
                <div className="row">
                  <div className="col-lg-12"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="copyright">
                    <span>
                      {T.copyright} &copy; {new Date().getFullYear()}{' '}
                      <Link href="/">
                        <a>Batibau</a>
                      </Link>{' '}
                      {T.all_rights_reserved}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="fotter-bottom_menu">
                    <ul>
                      <li>
                        <Link href="/">
                          <a>{T.home}</a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/[lang]/about-us" as={`/${selectedLanguage.key}/about-us`}>
                          <a>{T.about}</a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/[lang]/contact" as={`/${selectedLanguage.key}/contact`}>
                          <a>{T.contact}</a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    selectedLanguage: state.language.selectedLanguage,
    user: state.login.user,
  }
}

export default connect(mapStateToProps, null)(Footer)
